/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { AndonLightColorEnumType } from "./AndonLightColorEnum"
import { RootStoreType } from "./index"


/**
 * ReasonBase
 * auto generated base class for the model ReasonModel.
 */
export const ReasonModelBase = ModelBase
  .named('Reason')
  .props({
    __typename: types.optional(types.literal("Reason"), "Reason"),
    acceptanceMonit: types.union(types.undefined, types.boolean),
    canBeRecommended: types.union(types.undefined, types.boolean),
    hasNote: types.union(types.undefined, types.boolean),
    id: types.identifier,
    isNoteRequired: types.union(types.undefined, types.boolean),
    leaf: types.union(types.undefined, types.boolean),
    name: types.union(types.undefined, types.string),
    parentId: types.union(types.undefined, types.null, types.string),
    position: types.union(types.undefined, types.integer),
    reasonGroupId: types.union(types.undefined, types.string),
    statusColor: types.union(types.undefined, AndonLightColorEnumType),
    temporary: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class ReasonModelSelector extends QueryBuilder {
  get acceptanceMonit() { return this.__attr(`acceptanceMonit`) }
  get canBeRecommended() { return this.__attr(`canBeRecommended`) }
  get hasNote() { return this.__attr(`hasNote`) }
  get id() { return this.__attr(`id`) }
  get isNoteRequired() { return this.__attr(`isNoteRequired`) }
  get leaf() { return this.__attr(`leaf`) }
  get name() { return this.__attr(`name`) }
  get parentId() { return this.__attr(`parentId`) }
  get position() { return this.__attr(`position`) }
  get reasonGroupId() { return this.__attr(`reasonGroupId`) }
  get statusColor() { return this.__attr(`statusColor`) }
  get temporary() { return this.__attr(`temporary`) }
}
export function selectFromReason() {
  return new ReasonModelSelector()
}

export const reasonModelPrimitives = selectFromReason().acceptanceMonit.canBeRecommended.hasNote.isNoteRequired.leaf.name.parentId.position.reasonGroupId.statusColor.temporary
