/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { IObservableArray } from "mobx"
import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ItemModel, ItemModelType } from "./ItemModel"
import { ItemModelSelector } from "./ItemModel.base"
import { WorkplaceModel, WorkplaceModelType } from "./WorkplaceModel"
import { WorkplaceModelSelector } from "./WorkplaceModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  items: IObservableArray<ItemModelType>;
  workplaces: IObservableArray<WorkplaceModelType>;
}

/**
 * OrderBase
 * auto generated base class for the model OrderModel.
 */
export const OrderModelBase = withTypedRefs<Refs>()(ModelBase
  .named('Order')
  .props({
    __typename: types.optional(types.literal("Order"), "Order"),
    executedCount: types.union(types.undefined, types.integer),
    id: types.identifier,
    items: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => ItemModel)))),
    note: types.union(types.undefined, types.null, types.string),
    number: types.union(types.undefined, types.string),
    plannedCount: types.union(types.undefined, types.integer),
    position: types.union(types.undefined, types.null, types.integer),
    startedAt: types.union(types.undefined, types.null, types.string),
    finishedAt: types.union(types.undefined, types.null, types.string),
    workplaces: types.union(types.undefined, types.array(MSTGQLRef(types.late((): any => WorkplaceModel)))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class OrderModelSelector extends QueryBuilder {
  get executedCount() { return this.__attr(`executedCount`) }
  get id() { return this.__attr(`id`) }
  get note() { return this.__attr(`note`) }
  get number() { return this.__attr(`number`) }
  get plannedCount() { return this.__attr(`plannedCount`) }
  get position() { return this.__attr(`position`) }
  get startedAt() { return this.__attr(`startedAt`) }
  get finishedAt() { return this.__attr(`finishedAt`) }
  items(builder: string | ItemModelSelector | ((selector: ItemModelSelector) => ItemModelSelector) | undefined) { return this.__child(`items`, ItemModelSelector, builder) }
  workplaces(builder: string | WorkplaceModelSelector | ((selector: WorkplaceModelSelector) => WorkplaceModelSelector) | undefined) { return this.__child(`workplaces`, WorkplaceModelSelector, builder) }
}
export function selectFromOrder() {
  return new OrderModelSelector()
}

export const orderModelPrimitives = selectFromOrder().executedCount.note.number.plannedCount.position.startedAt.finishedAt
