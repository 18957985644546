import { createProductPayloadModelPrimitives } from '@/models/CreateProductPayloadModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { productModelPrimitives, selectFromProduct } from '@/models/ProductModel.base';

export default {
  ALL: productModelPrimitives.toString(),
  AUTOCOMPLETE: selectFromProduct().id.name.number.toString(),
  CREATE_PRODUCT_PAYLOAD_ALL: createProductPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .product(productModelPrimitives)
    .toString(),
};
