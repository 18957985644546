import { useEffect, useRef } from 'react';

import { Button as StyledButton } from './styled';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  size?: 'small' | 'normal' | 'large';
  fullWidth?: boolean;
  focus?: boolean;
  selected?: boolean;
  'data-testid'?: string;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  type,
  primary,
  size = 'normal',
  fullWidth = false,
  onClick,
  focus,
  disabled,
  style,
  'data-testid': dataTestID,
  selected,
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (focus) {
      buttonRef.current?.focus();
    }
  }, [focus]);

  return (
    <StyledButton
      ref={buttonRef}
      style={style}
      type={type}
      $primary={primary}
      size={size}
      fullWidth={fullWidth}
      whileTap="pressed"
      onClick={onClick}
      disabled={disabled}
      data-testid={dataTestID}
      selected={selected}
    >
      {children}
    </StyledButton>
  );
};

export { ButtonHint, ButtonIcon } from './styled';
