import { Container } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';

import { ThemeInterface } from '@/theme';

interface DialogBackgroundProps {
  alignToCenter: boolean;
}

interface DialogContentProps {
  padding?: string;
}

interface DialogMessageProps {
  noTopGutter?: boolean;
  textSmall?: boolean;
}

export const DialogBackground = styled(BaseModalBackground)<DialogBackgroundProps>`
  overflow-y: auto;
  backdrop-filter: blur(3.15px);
  ${({ alignToCenter }) => css`
    align-items: ${alignToCenter ? 'center' : 'flex-start'};
  `}
`;

export const Dialog = Modal.styled`
  color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.white};
  border-radius: 6px;
  background-color: ${({ theme }: { theme: ThemeInterface }) => theme.colors.mineSchaftDark};
`;

export const DialogTitle = styled.h4`
  min-height: 56px;
  margin: 0;
  padding: 1em 32px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.pixelWhite};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  background: ${({ theme }) => theme.gradients.dialogBar};
`;

export const DialogSubtitle = styled.h4`
  margin-top: 0;
  font-weight: normal;
  > strong {
    font-weight: bold;
  }
  > span {
    display: block;
    margin-top: 0.5em;
  }
`;

export const DialogContent = styled(Container)<DialogContentProps>`
  min-width: 500px;
  padding: ${(props) => props.padding || '20px 40px'};
  ${({ minHeight }) =>
    minHeight &&
    css`
      @media (max-height: ${minHeight}px) {
        height: calc(100vh - 64px);
      }
    `}
  @media (min-width: 768px) {
    max-width: 805px;
  }
`;

export const DialogMessage = styled.div<DialogMessageProps>`
  max-width: 625px;
  margin: 32px 0;
  padding: 40px;
  text-align: center;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  background: #444444;
  ${({ textSmall }) =>
    textSmall &&
    css`
      font-size: 90%;
    `}
  ${({ noTopGutter }) =>
    noTopGutter &&
    css`
      margin-top: 0;
    `}
`;

export const DialogMessageTitle = styled.h4`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.pixelWhite};
`;

export const DialogFooter = styled.div`
  margin: auto -32px 0 -32px;
  padding: 16px 32px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: ${({ theme }) => theme.gradients.dialogBar};
`;

export const DialogFooterLink = styled.a`
  margin-right: 40px;
  &:hover {
    color: ${({ theme }) => theme.colors.pixelWhite};
    cursor: pointer;
  }
`;
