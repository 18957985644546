import { Dialog as StyledDialog, DialogContent, DialogTitle } from './styled';

interface DialogProps {
  children: React.ReactNode;
  title?: string | null;
  open?: boolean;
  hidden?: boolean;
  minHeight?: number;
  contentPadding?: string;
  'data-testid'?: string;
}

export const Dialog: React.FC<DialogProps> = ({
  children,
  title,
  open,
  hidden,
  minHeight,
  contentPadding,
  'data-testid': dataTestID,
}) => {
  return (
    <StyledDialog
      className="dialog"
      data-testid={dataTestID}
      isOpen={!!open}
      style={{
        display: hidden ? 'none' : 'block',
        ...(minHeight && {
          minHeight: `${minHeight}px`,
        }),
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent minHeight={minHeight} padding={contentPadding}>
        {children}
      </DialogContent>
      {minHeight && (
        <style>{`
          @media (max-height: ${minHeight}px) {
            .dialog {
              min-height: auto !important;
              height: 100vh;
            }
            .dialog .container {
              height: calc(100vh - 160px);
            }
          }
      `}</style>
      )}
    </StyledDialog>
  );
};

export { default as DialogBackground } from './Background';
export { default as DialogMessage } from './Message';

export { DialogStep } from './DialogStep';

export { DialogFooter, DialogFooterLink, DialogSubtitle } from './styled';
