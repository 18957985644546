import { ReactElement, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { Button, CenteringContainer } from '@/components/core';
import List from '@/components/core/List';
import { StatusScreenElement, StatusScreenElementHeader, Title } from '@/components/StatusScreen/styled';
import { getWorkplaceConfig } from '@/helpers';
import { createOrderExecutionListItems } from '@/helpers/createOrderListItems';
import { useLastOrderExecution, useLastStatusChange, useOrderExecutionModalState } from '@/hooks';
import loader from '@/images/loader.svg';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

import FinishOrderExecutionModal from '../FinishOrderExecutionModal';
import OrderExecutionModal from '../OrderExecutionModal';

import { OrderButtonsContainer } from './styled';

const OrderInfo = observer((): ReactElement => {
  const { data, loading } = useCompanyData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const workplaceConfig = getWorkplaceConfig(data?.companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const orderExecutionConfig = workplaceConfig?.orderExecutionConfig;
  const productConfig = workplaceConfig?.productConfig;

  const lastOrderExecution = useLastOrderExecution();
  const lastStatusChange = useLastStatusChange();
  const [orderExecutionFeatureEnabled, setOrderExecutionFeatureEnabled] = useState<boolean>(false);
  const [finishOrderExecutionModalOpened, setFinishOrderExecutionModalOpened] = useState<boolean>(false);
  const orderExecutionModalState = useOrderExecutionModalState({ orderConfig, productConfig });
  const intl = useIntl();

  const { setShowExecutionInProgressAlert, setOrderExecutionModalOpened } = orderExecutionModalState;

  useEffect(() => {
    if (workplaceConfig && !loading) {
      setOrderExecutionFeatureEnabled(!!orderExecutionConfig?.featureEnabled);
    }
  }, [workplaceConfig, orderExecutionConfig?.featureEnabled, loading]);

  const orderButtonStyle = { textAlign: 'left', padding: '15px', height: '100%', marginTop: 0 } as const;

  const renderOrderInfoHeader = () => {
    return (
      <StatusScreenElementHeader>
        <Title>
          {!!orderConfig?.alternativeName
            ? intl.formatMessage({
                defaultMessage: 'Order Info',
                description: 'Order info alternative title',
              })
            : intl.formatMessage({
                defaultMessage: 'Order Info',
                description: 'Order info title',
              })}
        </Title>
      </StatusScreenElementHeader>
    );
  };
  if (loading)
    return (
      <CenteringContainer>
        <img src={loader} alt="" data-testid="order-info_loader" />
      </CenteringContainer>
    );
  if (!loading && orderExecutionFeatureEnabled && data?.companyConfig)
    return (
      <>
        <StatusScreenElement
          style={{ gridTemplateRows: 'auto auto 1fr' }}
          data-testid="order-info-container"
          gridArea="ORDERS"
        >
          {renderOrderInfoHeader()}
          <OrderButtonsContainer>
            <Button
              style={orderButtonStyle}
              onClick={() => {
                if (orderExecutionConfig?.stopEnabled && lastOrderExecution && !lastOrderExecution.finishedAt) {
                  setShowExecutionInProgressAlert(true);
                } else {
                  setOrderExecutionModalOpened(true);
                }
              }}
              type="button"
              data-testid="start-order-execution-button"
            >
              {!!orderConfig?.alternativeName
                ? intl.formatMessage({
                    defaultMessage: 'Start order execution',
                    description: 'Order info start order execution button alternative text',
                  })
                : intl.formatMessage({
                    defaultMessage: 'Start order execution',
                    description: 'Order info start order execution button text',
                  })}
            </Button>
            {orderExecutionConfig?.stopEnabled && (
              <Button
                style={orderButtonStyle}
                onClick={() => setFinishOrderExecutionModalOpened(true)}
                type="button"
                data-testid="finish-order-execution-button"
                disabled={!lastOrderExecution || !!lastOrderExecution.finishedAt}
              >
                {intl.formatMessage({
                  defaultMessage: 'Finish this order',
                  description: 'Order info button finish label',
                })}
              </Button>
            )}
          </OrderButtonsContainer>
          {orderConfig && productConfig && lastOrderExecution && !lastOrderExecution.finishedAt && (
            <List
              rowStyles={{ minHeight: '50px', maxHeight: '100px' }}
              rowHeight="25%"
              data-testid={`order-id-${lastOrderExecution.id}`}
              items={createOrderExecutionListItems({
                orderConfig,
                productConfig,
                orderExecution: lastOrderExecution,
                intl,
              })}
            />
          )}
        </StatusScreenElement>
        <OrderExecutionModal state={orderExecutionModalState} companyConfig={data.companyConfig} />
        {lastOrderExecution && lastStatusChange && (
          <FinishOrderExecutionModal
            open={finishOrderExecutionModalOpened}
            setOpen={setFinishOrderExecutionModalOpened}
            orderExecution={lastOrderExecution}
            lastStatusChange={lastStatusChange}
            orderExecutionModalState={orderExecutionModalState}
          />
        )}
      </>
    );
  return (
    <StatusScreenElement data-testid="order-info-container-feature-disabled" gridArea="ORDERS">
      {renderOrderInfoHeader()}
      <CenteringContainer>Feature disabled</CenteringContainer>
    </StatusScreenElement>
  );
});
export default OrderInfo;
