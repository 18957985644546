import { useIntl } from 'react-intl';

import { FormikProps } from 'formik';
import { Col, Container, Row } from 'styled-bootstrap-grid';

import { DialogStep, FieldError, TextField } from '@/components/core';
import { getPrefixedNumber } from '@/helpers/orders-executions';
import { OrderExecutionConfigModelType, ProductConfigModelType } from '@/models';
import { OrderExecutionFormValues, OrderExecutionModalState } from '@/types';

import { FormControl } from '../../styled';
import useHandlers from '../hooks/useHandlers';

interface OrderStepProps {
  active?: boolean;
  completed?: boolean;
  formProps: FormikProps<OrderExecutionFormValues>;
  orderExecutionModalState: OrderExecutionModalState;
  orderExecutionConfig: OrderExecutionConfigModelType;
  productConfig: ProductConfigModelType;
  handlers: ReturnType<typeof useHandlers>;
}

const ProductStep: React.FC<OrderStepProps> = ({
  active,
  completed,
  formProps,
  orderExecutionModalState,
  orderExecutionConfig,
  productConfig,
  handlers,
}) => {
  const {
    selectedProduct,
    steps,
    activeStep,
    setActiveStep,
    completedSteps,
    setCompletedSteps,
    removeCompletedStep,
    getFieldsInCurrentStep,
    validateCurrentStep,
    setCurrentField,
    isCurrentField,
    setLastActiveField,
    productNumberPrefix,
  } = orderExecutionModalState;
  const { handleKeyPress } = handlers;
  const intl = useIntl();

  const shouldRenderField = (fieldName: string) => {
    return getFieldsInCurrentStep().includes(fieldName);
  };

  const getSummaryRecords = () => {
    const product = selectedProduct || formProps.values.product;

    let records = [];

    if (product?.name) {
      records.push({
        label: !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Product name',
              description: 'Product summary name record alternative label',
            })
          : intl.formatMessage({
              defaultMessage: 'Product name',
              description: 'Product summary name record label',
            }),
        value: product.name,
      });
    }
    if (product?.number) {
      records.push({
        label: !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Product number',
              description: 'Product summary number record alternative label',
            })
          : intl.formatMessage({
              defaultMessage: 'Product number',
              description: 'Product summary number record label',
            }),
        value: getPrefixedNumber(product.number, formProps.values.product?.numberPrefix),
      });
    }
    return records;
  };

  return (
    <DialogStep
      active={active}
      completed={completed}
      step={2}
      setActiveStep={async (stepNumber) => {
        const step = steps[stepNumber - 1];

        if (activeStep && (await validateCurrentStep())) {
          setCompletedSteps([...completedSteps, activeStep]);
        }
        removeCompletedStep(step);

        setActiveStep(step);

        setCurrentField(`${step}.${getFieldsInCurrentStep()[0]}`);
      }}
      title={
        !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Product',
              description: 'Product step alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'Product',
              description: 'Product step title',
            })
      }
      subtitle={
        !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Scan or enter product data and click "Next".',
              description: 'Product step alternative subtitle',
            })
          : intl.formatMessage({
              defaultMessage: 'Scan or enter product data and click "Next".',
              description: 'Product step subtitle',
            })
      }
      noteTitle={orderExecutionConfig?.productInternalNoteTitle}
      noteText={orderExecutionConfig?.productInternalNoteText}
      summaryRecords={getSummaryRecords()}
      data-testid="order-execution-form-product-step"
    >
      <Container style={{ padding: 0 }}>
        <Row>
          {shouldRenderField('name') && (
            <Col col={4} noGutter>
              <FormControl>
                <TextField
                  name="product.name"
                  label={
                    !!productConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Product name',
                          description: 'Order execution form product name field alternative label',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Product name',
                          description: 'Order execution form product name field label',
                        })
                  }
                  data-testid="order-execution-product-name-field"
                  placeholder={
                    !!productConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Enter product name',
                          description: 'Order execution form product name field alternative placeholder',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Enter product name',
                          description: 'Order execution form product name field placeholder',
                        })
                  }
                  value={formProps.values.product?.name}
                  onChange={formProps.handleChange}
                  onKeyDown={handleKeyPress}
                  onBlur={() => {
                    setCurrentField(null);

                    setLastActiveField('product.name');
                  }}
                  onFocus={() => setCurrentField('product.name')}
                  focus={isCurrentField('product.name')}
                  error={formProps.touched.product?.name && Boolean(formProps.errors.product?.name)}
                />
                <FieldError
                  message={formProps.touched.product?.name && formProps.errors.product?.name}
                  data-testid="order-execution-product-name-field-error"
                />
              </FormControl>
            </Col>
          )}
          {productNumberPrefix && (
            <Col col={3.5} offset={shouldRenderField('name') ? 0.5 : 0} noGutter>
              <FormControl rightGutter>
                <TextField
                  name="product.numberPrefix"
                  label="Prefix"
                  data-testid="order-execution-product-number-prefix-field"
                  placeholder={
                    !!productConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Enter prefix',
                          description: 'Order execution form product number prefix field alternative placeholder',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Enter prefix',
                          description: 'Order execution form product number prefix field placeholder',
                        })
                  }
                  value={formProps.values.product?.numberPrefix}
                  onChange={formProps.handleChange}
                  onKeyDown={handleKeyPress}
                  onBlur={() => {
                    setCurrentField(null);

                    setLastActiveField('product.numberPrefix');
                  }}
                  onFocus={() => setCurrentField('product.numberPrefix')}
                  focus={isCurrentField('product.numberPrefix')}
                  error={formProps.touched.product?.numberPrefix && Boolean(formProps.errors.product?.numberPrefix)}
                />
                <FieldError
                  message={formProps.touched.product?.numberPrefix && formProps.errors.product?.numberPrefix}
                  data-testid="order-execution-product-number-prefix-field-error"
                />
              </FormControl>
            </Col>
          )}
          {shouldRenderField('number') && (
            <Col col={4} noGutter>
              <FormControl>
                <TextField
                  name="product.number"
                  label={
                    !!productConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Product number',
                          description: 'Order execution form product number field alternative label',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Product number',
                          description: 'Order execution form product number field label',
                        })
                  }
                  data-testid="order-execution-product-number-field"
                  placeholder={
                    !!productConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Enter product number',
                          description: 'Order execution form product number field alternative placeholder',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Enter product number',
                          description: 'Order execution form product number field placeholder',
                        })
                  }
                  value={formProps.values.product?.number}
                  onChange={formProps.handleChange}
                  onKeyDown={handleKeyPress}
                  onBlur={() => {
                    setCurrentField(null);

                    setLastActiveField('product.number');
                  }}
                  onFocus={() => setCurrentField('product.number')}
                  focus={isCurrentField('product.number')}
                  error={formProps.touched.product?.number && Boolean(formProps.errors.product?.number)}
                />
                <FieldError
                  message={formProps.touched.product?.number && formProps.errors.product?.number}
                  data-testid="order-execution-product-number-field-error"
                />
              </FormControl>
            </Col>
          )}
        </Row>
      </Container>
    </DialogStep>
  );
};

export default ProductStep;
