import { pick } from 'lodash';
import { useLocalObservable } from 'mobx-react-lite';

import { OrderConfigModelType, ProductConfigModelType } from '@/models';
import { OrderExecutionModalState } from '@/types';

interface OrderExecutionModalStateOptions {
  initial?: Partial<OrderExecutionModalState>;
  orderConfig?: OrderConfigModelType;
  productConfig?: ProductConfigModelType;
}

const useOrderExecutionModalState = (options?: OrderExecutionModalStateOptions): OrderExecutionModalState => {
  const { initial, orderConfig, productConfig } = options || {};
  const orderNumberPrefix = orderConfig?.numberPrefix || '';
  const productNumberPrefix = productConfig?.numberPrefix || '';

  return useLocalObservable<OrderExecutionModalState>(() => ({
    /*
     * Modal view management
     */
    previousView: null,
    setPreviousView(value) {
      this.previousView = value;
    },
    currentView: null,
    setCurrentView(value) {
      this.previousView = this.currentView;

      this.currentView = value;
    },
    showExecutionInProgressAlert: false,
    setShowExecutionInProgressAlert(value) {
      this.showExecutionInProgressAlert = value;
    },
    showAlreadyPlannedAlert: false,
    setShowAlreadyPlannedAlert(value) {
      this.showAlreadyPlannedAlert = value;
    },
    showUnsavedChangesAlert: false,
    setShowUnsavedChangesAlert(value) {
      this.showUnsavedChangesAlert = value;
    },
    /*
     * Form state management
     */
    formProps: null,
    setFormProps(props) {
      this.formProps = props;
    },
    validating: false,
    setValidating(value) {
      this.validating = value;
    },
    /*
     * Orders management
     */
    orderNumberPrefix,
    startedOrders: [],
    setStartedOrders(orders) {
      this.startedOrders = orders;
    },
    addStartedOrder(order) {
      this.startedOrders = [order, ...(this.startedOrders || [])];
    },
    removeStartedOrder(order) {
      this.startedOrders = this.startedOrders?.filter((startedOrder) => startedOrder.id !== order.id) || [];
    },
    plannedOrders: [],
    addPlannedOrder(order) {
      this.plannedOrders = [order, ...(this.plannedOrders || [])];
    },
    setPlannedOrders(orders) {
      this.plannedOrders = orders;
    },
    removePlannedOrder(order) {
      this.plannedOrders = this.plannedOrders?.filter((plannedOrder) => plannedOrder.id !== order.id) || [];
    },
    existingOrdersList: [],
    setExistingOrdersList(orders) {
      this.existingOrdersList = orders;
    },
    selectedOrder: null,
    setSelectedOrder(order) {
      this.selectedOrder = order;
    },
    /*
     * Products management
     */
    productNumberPrefix,
    selectedProduct: null,
    setSelectedProduct(product) {
      this.selectedProduct = product;
    },
    /*
     * Order execution steps management
     */
    steps: [],
    setSteps(steps) {
      this.steps = steps;
    },
    activeStep: null,
    setActiveStep(step) {
      this.activeStep = step;
    },
    completedSteps: [],
    setCompletedSteps(steps) {
      this.completedSteps = steps;
    },
    removeCompletedStep(step) {
      this.setCompletedSteps(this.completedSteps.filter((completedStep) => completedStep !== step));
    },
    async validateCurrentStep() {
      if (this.activeStep) {
        this.setValidating(true);

        const errors = await this.formProps?.validateForm();

        const stepErrors = pick(errors, this.activeStep);

        this.setValidating(false);

        return !Object.keys(stepErrors).length;
      }
      return false;
    },
    moveToNextStep() {
      if (this.activeStep) {
        const notCompletedSteps = this.steps.filter((step) => !this.completedSteps.includes(step));

        const nextStep = notCompletedSteps[notCompletedSteps.indexOf(this.activeStep) + 1];

        this.setCompletedSteps([...this.completedSteps, this.activeStep]);

        if (nextStep) {
          this.setActiveStep(nextStep);

          const firstFieldInNextStep = this.getFieldsInCurrentStep()[0];

          if (firstFieldInNextStep) {
            this.setCurrentField(`${nextStep}.${firstFieldInNextStep}`);
          }
        } else {
          this.setActiveStep(null);
        }
      }
    },
    allStepsCompleted() {
      return this.steps.every((step) => this.completedSteps.includes(step));
    },
    clearSteps() {
      this.setActiveStep(null);
      this.setCompletedSteps([]);
    },
    /*
     * Order execution fields management
     */
    fieldsNames: [],
    setFieldsNames(fields: string[]) {
      this.fieldsNames = fields;
    },
    getFieldsInCurrentStep() {
      return this.fieldsNames
        .filter((field) => field.split('.')[0] === this.activeStep)
        .map((field) => field.split('.')[1]);
    },
    shouldRenderStep(step) {
      return this.fieldsNames.some((field) => field.split('.')[0] === step);
    },
    shouldRenderField(field) {
      return this.fieldsNames.includes(field);
    },
    currentField: null,
    setCurrentField(field: string | null) {
      this.currentField = field;
    },
    isCurrentField(field) {
      return this.currentField === field;
    },
    async validateCurrentField() {
      if (this.activeStep && (this.currentField || this.lastActiveField)) {
        this.setValidating(true);

        const errors = await this.formProps?.validateForm();
        const currentFieldName = (this.currentField || (this.lastActiveField as string))?.split('.')[1];

        // @ts-expect-error
        if (errors[this.activeStep]?.[currentFieldName]) {
          this.formProps?.setErrors({
            // @ts-expect-error
            [this.activeStep]: { [currentFieldName]: errors[this.activeStep][currentFieldName] },
          });
          this.formProps?.setTouched({
            [this.activeStep]: {
              [currentFieldName]: true,
            },
          });
        }
        this.setValidating(false);

        // @ts-expect-error
        return !this.formProps?.errors[this.activeStep]?.[currentFieldName];
      }
      return false;
    },
    lastActiveField: null,
    setLastActiveField(field) {
      this.lastActiveField = field;
    },
    moveToNextField() {
      if (this.currentField || this.lastActiveField) {
        const currentFieldIndex = this.fieldsNames.indexOf(this.currentField || (this.lastActiveField as string));

        if (currentFieldIndex !== this.fieldsNames.length - 1) {
          this.setCurrentField(this.fieldsNames[currentFieldIndex + 1]);
        }
      }
    },
    /*
     * Order execution state management
     */
    afterStartReason: null,
    setAfterStartReason(reason) {
      this.afterStartReason = reason;
    },
    mutationInProgress: false,
    setMutationInProgress(value) {
      this.mutationInProgress = value;
    },
    orderExecutionModalOpened: false,
    setOrderExecutionModalOpened(value) {
      this.orderExecutionModalOpened = value;
    },
    orderExecutionModalHidden: false,
    setOrderExecutionModalHidden(value) {
      this.orderExecutionModalHidden = value;
    },
    ...initial,
  }));
};

export default useOrderExecutionModalState;
