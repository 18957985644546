import { useIntl } from 'react-intl';

import { FormikProps } from 'formik';
import { Col, Container, Row } from 'styled-bootstrap-grid';

import { Autocomplete, DialogStep, FieldError, TextField } from '@/components/core';
import { getPrefixedNumber } from '@/helpers/orders-executions';
import { OrderConfigModelType, OrderExecutionConfigModelType } from '@/models';
import { OrderExecutionFormValues, OrderExecutionModalState } from '@/types';

import { FormControl } from '../../styled';
import useHandlers from '../hooks/useHandlers';

interface OrderStepProps {
  active?: boolean;
  completed?: boolean;
  formProps: FormikProps<OrderExecutionFormValues>;
  orderExecutionModalState: OrderExecutionModalState;
  orderConfig: OrderConfigModelType;
  orderExecutionConfig: OrderExecutionConfigModelType;
  handlers: ReturnType<typeof useHandlers>;
}

const OrderStep: React.FC<OrderStepProps> = ({
  active,
  completed,
  formProps,
  orderExecutionModalState,
  orderConfig,
  orderExecutionConfig,
  handlers,
}) => {
  const intl = useIntl();
  const {
    steps,
    activeStep,
    setActiveStep,
    completedSteps,
    setCompletedSteps,
    removeCompletedStep,
    getFieldsInCurrentStep,
    validateCurrentStep,
    existingOrdersList,
    setCurrentField,
    isCurrentField,
    setLastActiveField,
    orderNumberPrefix,
  } = orderExecutionModalState;
  const { handleKeyPress } = handlers;

  const getSummaryRecords = () => {
    return [
      {
        value: getPrefixedNumber(formProps.values.order.number, formProps.values.order.numberPrefix),
      },
    ];
  };

  return (
    <DialogStep
      active={active}
      completed={completed}
      step={1}
      setActiveStep={async (stepNumber) => {
        const step = steps[stepNumber - 1];

        if (activeStep && (await validateCurrentStep())) {
          setCompletedSteps([...completedSteps, activeStep]);
        }
        removeCompletedStep(step);

        setActiveStep(step);

        setCurrentField(`${step}.${getFieldsInCurrentStep()[0]}`);
      }}
      title={
        !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Order',
              description: 'Order step alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'Order',
              description: 'Order step title',
            })
      }
      subtitle={
        !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Scan or enter order number and click "Next".',
              description: 'Order step alternative subtitle',
            })
          : intl.formatMessage({
              defaultMessage: 'Scan or enter order number and click "Next".',
              description: 'Order step subtitle',
            })
      }
      noteTitle={orderExecutionConfig?.orderInternalNoteTitle}
      noteText={orderExecutionConfig?.orderInternalNoteText}
      summaryRecords={getSummaryRecords()}
      data-testid="order-execution-form-order-step"
    >
      <Container style={{ padding: 0 }}>
        <Row>
          {orderNumberPrefix && (
            <Col col="auto" noGutter>
              <FormControl rightGutter>
                <TextField
                  name="order.numberPrefix"
                  label="Prefix"
                  data-testid="order-execution-order-number-prefix-field"
                  placeholder={
                    !!orderConfig?.alternativeName
                      ? intl.formatMessage({
                          defaultMessage: 'Enter prefix',
                          description: 'Order execution form order number prefix field alternative placeholder',
                        })
                      : intl.formatMessage({
                          defaultMessage: 'Enter prefix',
                          description: 'Order execution form order number prefix field placeholder',
                        })
                  }
                  value={formProps.values.order.numberPrefix}
                  onChange={formProps.handleChange}
                  onKeyDown={handleKeyPress}
                  onBlur={() => {
                    setCurrentField(null);

                    setLastActiveField('order.numberPrefix');
                  }}
                  onFocus={() => setCurrentField('order.numberPrefix')}
                  focus={isCurrentField('order.numberPrefix')}
                  width="160px"
                />
                <FieldError
                  message={formProps.touched.order?.numberPrefix && formProps.errors.order?.numberPrefix}
                  data-testid="order-execution-order-number-prefix-field-error"
                />
              </FormControl>
            </Col>
          )}
          <Col col="auto" noGutter>
            <FormControl>
              <Autocomplete
                name="order.number"
                label={
                  !!orderConfig?.alternativeName
                    ? intl.formatMessage({
                        defaultMessage: 'Order number',
                        description: 'Order execution form order number field alternative label',
                      })
                    : intl.formatMessage({
                        defaultMessage: 'Order number',
                        description: 'Order execution form order number field label',
                      })
                }
                width="300px"
                data-testid="order-execution-order-number-field"
                items={existingOrdersList}
                itemLabelKey="number"
                ignoreCase={false}
                placeholder={
                  !!orderConfig?.alternativeName
                    ? intl.formatMessage({
                        defaultMessage: 'Enter order number',
                        description: 'Order execution form order number field alternative placeholder',
                      })
                    : intl.formatMessage({
                        defaultMessage: 'Enter order number',
                        description: 'Order execution form order number field placeholder',
                      })
                }
                initialValue={formProps.values.order.number}
                value={formProps.values.order.number}
                onChange={formProps.handleChange}
                onSelect={(value) => formProps.setFieldValue('order.number', value)}
                onKeyDown={handleKeyPress}
                onBlur={() => {
                  setCurrentField(null);

                  setLastActiveField('order.number');
                }}
                onFocus={() => setCurrentField('order.number')}
                focus={isCurrentField('order.number')}
                error={formProps.touched.order?.number && Boolean(formProps.errors.order?.number)}
                hideClear
                hideSearchIcon
              />
              <FieldError
                message={formProps.touched.order?.number && formProps.errors.order?.number}
                data-testid="order-execution-order-number-field-error"
              />
            </FormControl>
          </Col>
        </Row>
      </Container>
    </DialogStep>
  );
};

export default OrderStep;
