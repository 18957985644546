import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Switch from 'react-switch';

import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { Button, Dialog, DialogSubtitle } from '@/components/core';
import { NoteForm, SwitchLabel } from '@/components/forms';
import { getReasonIcon } from '@/helpers/reasons';
import { buildReasonURL } from '@/helpers/urls/buildReasonURL';
import { ReasonModelType } from '@/models';
import { theme } from '@/theme';
import type { ColumnsStore, ReasonsParamTypes } from '@/types';

import { ButtonIcon } from './styled';

interface ModalProps {
  reason: ReasonModelType;
  open: boolean;
  columns: ColumnsStore;
  onConfirm: (note?: string) => void;
}

const ReasonActionModal: React.FC<ModalProps> = observer(({ reason, open, columns, onConfirm }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [searchParams] = useSearchParams();
  const reasonHasBeenRecommended = searchParams.get('src') === 'ai';
  const [showNoteForm, setShowNoteForm] = useState<boolean>(!!reason.hasNote && !!reason.isNoteRequired);
  const navigate = useNavigate();
  const intl = useIntl();

  useEffect(() => {
    setShowNoteForm(!!reason.hasNote && !!reason.isNoteRequired);
  }, [open, reason.hasNote, reason.isNoteRequired]);

  const closeModal = () => {
    if (!reasonHasBeenRecommended && reason.parentId) {
      columns.update(reason.parentId, { selectedID: null });
      navigate(buildReasonURL(workplaceID, reason.parentId));
    } else {
      columns.update(null, { selectedID: null });
      navigate(buildReasonURL(workplaceID));
    }
  };

  const toggleNoteFormVisibility = (): void => setShowNoteForm((value) => !value);

  const renderButtons = (): React.ReactElement => (
    <Row style={{ marginTop: showNoteForm ? '20px' : '0' }}>
      <Col col={6}>
        <Button type="button" size="large" data-testid="cancel-button" onClick={() => closeModal()} fullWidth>
          <FormattedMessage defaultMessage="No" description="Reason action modal cancel button" />
        </Button>
      </Col>
      <Col col={6}>
        {showNoteForm ? (
          <Button type="submit" size="large" data-testid="submit-button" primary fullWidth>
            <FormattedMessage defaultMessage="Yes" description="Reason action modal submit button" />
          </Button>
        ) : (
          <Button type="button" size="large" data-testid="submit-button" onClick={() => onConfirm()} primary fullWidth>
            <FormattedMessage defaultMessage="Yes" description="Reason action modal submit button" />
          </Button>
        )}
      </Col>
    </Row>
  );

  return (
    <Dialog
      title={intl.formatMessage({
        defaultMessage: 'Status change',
        description: 'Reason action modal title',
      })}
      open={open}
      data-testid="reason-action-modal"
    >
      <Row>
        <Col col="auto">
          <ButtonIcon src={getReasonIcon(reason)} />
        </Col>
        <Col col="auto">
          <DialogSubtitle>
            <FormattedMessage
              defaultMessage="Change status to {reasonName}?"
              description="Reason action modal subtitle"
              values={{ reasonName: <strong>{reason.name}</strong> }}
            />
          </DialogSubtitle>
          {reason.hasNote && !reason.isNoteRequired && (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <SwitchLabel htmlFor="note-form-switch">
              <Switch
                id="note-form-switch"
                data-testid="note-form-switch"
                checked={showNoteForm}
                onChange={toggleNoteFormVisibility}
                onColor={theme.colors.dodgerBlue}
                checkedIcon={false}
                uncheckedIcon={false}
              />
              <span>
                <FormattedMessage defaultMessage="Add note" description="Reason action modal add note label" />
              </span>
            </SwitchLabel>
          )}
          {showNoteForm ? <NoteForm onSubmit={onConfirm}>{renderButtons()}</NoteForm> : renderButtons()}
        </Col>
      </Row>
    </Dialog>
  );
});

export default ReasonActionModal;
