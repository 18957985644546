import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getWorkplaceConfig } from '@/helpers';
import { ProductConfigModelType } from '@/models';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

const useProductConfig = () => {
  const { data, loading } = useCompanyData();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [productConfig, setProductConfig] = useState<ProductConfigModelType>();

  useEffect(() => {
    if (!loading) setProductConfig(getWorkplaceConfig(data?.companyConfig, workplaceID)?.productConfig);
  }, [data, loading, workplaceID]);

  return productConfig;
};

export default useProductConfig;
