import { FormattedMessage, useIntl } from 'react-intl';

import { observer } from 'mobx-react-lite';

import { OrderExecutionModalView } from '@/components/const';
import { ConfirmationModal, DialogMessage } from '@/components/core';
import { OrderExecutionModalState } from '@/types';

interface UnsavedChangesAlertModalProps {
  state: OrderExecutionModalState;
}

const UnsavedChangesAlertModal: React.FC<UnsavedChangesAlertModalProps> = observer(({ state }) => {
  const intl = useIntl();

  const {
    previousView,
    setCurrentView,
    showUnsavedChangesAlert,
    setShowUnsavedChangesAlert,
    clearSteps,
    formProps,
    setSelectedProduct,
    setOrderExecutionModalOpened,
    setOrderExecutionModalHidden,
  } = state;

  const handleConfirm = () => {
    clearSteps();

    setSelectedProduct(null);

    formProps?.resetForm();

    setShowUnsavedChangesAlert(false);

    if (previousView === OrderExecutionModalView.PLANNED) {
      setCurrentView(OrderExecutionModalView.PLANNED);

      setOrderExecutionModalHidden(false);
    } else {
      setOrderExecutionModalHidden(false);
      setOrderExecutionModalOpened(false);
    }
  };
  const handleCancel = () => {
    setShowUnsavedChangesAlert(false);

    setOrderExecutionModalHidden(false);
  };

  return (
    <ConfirmationModal
      isOpen={showUnsavedChangesAlert}
      data-testid="unsaved-changes-alert-modal"
      onSuccess={handleConfirm}
      onFailure={handleCancel}
    >
      <DialogMessage
        title={intl.formatMessage({
          defaultMessage: 'Attention!',
          description: 'Unsaved changes alert modal title',
        })}
      >
        <FormattedMessage
          defaultMessage="Do you want to cancel data entry?"
          description="Unsaved changes alert modal message"
        />
      </DialogMessage>
    </ConfirmationModal>
  );
});

export default UnsavedChangesAlertModal;
