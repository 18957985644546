export const hexToRgba = (hex: string, alpha: number = 1): string => {
  const hexWithoutHash = hex.replace('#', '');
  const bigint = parseInt(hexWithoutHash, 16);

  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
