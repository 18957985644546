import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { OrderExecutionModalView } from '@/components/const';
import { Button, DialogFooter, DialogMessage } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { ReasonsParamTypes } from '@/types';

import { OrderExecutionModalProps } from '../..';

const NoPlanView: React.FC<OrderExecutionModalProps> = observer(({ state, companyConfig }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const intl = useIntl();

  const workplaceConfig = getWorkplaceConfig(companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const orderExecutionConfig = workplaceConfig?.orderExecutionConfig;
  const orderCreationAllowed = companyConfig?.roleConfig?.orderCreationAllowed;
  const { setCurrentView, setOrderExecutionModalOpened } = state;

  const handleCancel = () => {
    setOrderExecutionModalOpened(false);
  };

  const handleConfimAction = () => {
    if (!companyConfig?.roleConfig?.orderCreationAllowed) {
      setOrderExecutionModalOpened(false);
    } else {
      setCurrentView(OrderExecutionModalView.MANUAL);
    }
  };

  const renderNoPlanMessage = () => {
    return companyConfig?.roleConfig?.orderCreationAllowed ? (
      <>
        {orderExecutionConfig?.noPlanCanCreateOrderNote ||
          (!!orderConfig?.alternativeName ? (
            <FormattedMessage
              defaultMessage="You can add an order manually or contact your planner."
              description="Order execution modal no plan view can create order alternative message"
            />
          ) : (
            <FormattedMessage
              defaultMessage="You can add an order manually or contact your planner."
              description="Order execution modal no plan view can create order message"
            />
          ))}
      </>
    ) : (
      orderExecutionConfig?.noPlanNeedVerificationNote || (
        <FormattedMessage
          defaultMessage="Please contact planner for verification."
          description="Order execution modal no plan view need verification message"
        />
      )
    );
  };
  return (
    <Box display="block" data-testid="no-plan-view">
      <DialogMessage
        title={intl.formatMessage({
          defaultMessage: 'No orders execution plan',
          description: 'Order execution modal no plan view title',
        })}
        noTopGutter
      >
        {renderNoPlanMessage()}
      </DialogMessage>
      <DialogFooter>
        <Row justifyContent={orderCreationAllowed ? 'between' : 'end'}>
          {orderCreationAllowed && (
            <Col col="auto" noGutter>
              <Button type="button" size="large" onClick={handleCancel} data-testid="no-plan-view-cancel-button">
                <FormattedMessage defaultMessage="Cancel" description="No plan view cancel button" />
              </Button>
            </Col>
          )}
          <Col col="auto" noGutter>
            <Button
              type="button"
              size="large"
              onClick={handleConfimAction}
              data-testid="no-plan-view-confirm-button"
              primary
            >
              {orderCreationAllowed ? (
                !!orderConfig?.alternativeName ? (
                  <FormattedMessage
                    defaultMessage="Create order"
                    description="No plan view create order alternative button"
                  />
                ) : (
                  <FormattedMessage defaultMessage="Create order" description="No plan view create order button" />
                )
              ) : (
                <FormattedMessage defaultMessage="I Understand" description="No plan view confirm button" />
              )}
            </Button>
          </Col>
        </Row>
      </DialogFooter>
    </Box>
  );
});

export default NoPlanView;
