import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { TextTruncate } from '@/components/core';
import { Overlay } from '@/components/core/Overlay';
import { hasNestedPermittedChildrenTransitions } from '@/helpers/hasNestedChildrenPermittedTransitions';
import { isTransitionPermitted } from '@/helpers/isTransitionPermitted';
import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { mapStatusColorToTextColor } from '@/helpers/mapStatusColorToTextColor';
import { buildReasonURL } from '@/helpers/urls/buildReasonURL';
import arrowRightBlackFilled from '@/images/arrowRightBlackFilled.svg';
import arrowRightSemiTransparentFilled from '@/images/arrowRightSemiTransparentFilled.svg';
import folderBlackFilled from '@/images/folderBlackFilled.svg';
import folderSemiTransparentFilled from '@/images/folderSemiTransparentFilled.svg';
import gearBlackFilled from '@/images/gearBlackFilled.svg';
import gearSemiTransparentFilled from '@/images/gearSemiTransparentFilled.svg';
import { AndonLightColor, ReasonModelType } from '@/models';
import { useStatusChangeTransitionPermissions, useWorkplaceData } from '@/providers';
import type { ColumnsStore, ReasonsParamTypes } from '@/types';

import { Button, ButtonIcon } from './styled';

interface ReasonRowProps {
  reason: ReasonModelType;
  selected: boolean;
  columns: ColumnsStore;
  isLastColumn?: boolean;
}

const ReasonRow: React.FC<ReasonRowProps> = observer(
  ({ reason, selected, columns, isLastColumn }: ReasonRowProps): React.ReactElement => {
    const { statusChangeTransitionPermissions } = useStatusChangeTransitionPermissions();
    const { data } = useWorkplaceData();
    const { id: reasonID, parentId } = reason;
    const navigate = useNavigate();
    const { workplaceID, reasonID: selectedReasonId } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
    let disabled = true;

    if (reason.leaf) disabled = !isTransitionPermitted({ statusChangeTransitionPermissions, toReasonId: reason.id });

    if (!reason.leaf)
      disabled = !hasNestedPermittedChildrenTransitions({
        toReason: reason,
        reasons: data?.reasons,
        statusChangeTransitionPermissions,
      });

    const handleRowClick = () => {
      if (!selected) {
        const selectedReason = data?.reasons.find((r) => r.id === selectedReasonId);

        if (columns.state.length === 1 || !selectedReason || parentId === selectedReason?.id || !reason.leaf) {
          columns.select(parentId, reasonID);

          if (reason.leaf) {
            columns.removeAfter(parentId);
          } else {
            columns.insertAfter(parentId, reasonID);
          }
          navigate(buildReasonURL(workplaceID, reasonID));
        } else {
          columns.removeAfter(parentId);
          columns.update(parentId, { selectedID: null });
          navigate(buildReasonURL(workplaceID, reason.parentId || undefined));
        }
      } else {
        columns.removeAndDeselect(reasonID);
        navigate(buildReasonURL(workplaceID, reason.parentId || undefined));
      }
    };

    return (
      <Button
        textColor={mapStatusColorToTextColor(reason.statusColor as AndonLightColor)}
        backgroundColor={mapStatusColorToColorString(reason.statusColor as AndonLightColor)}
        disabled={disabled}
        selected={selected}
        data-testid={`row-${reasonID}`}
        onClick={handleRowClick}
        isLastColumn={isLastColumn}
      >
        <Overlay small />
        {!reason.leaf && (
          <ButtonIcon width="32px" src={selected || isLastColumn ? folderBlackFilled : folderSemiTransparentFilled} />
        )}
        {reason.leaf && <ButtonIcon src={selected || isLastColumn ? gearBlackFilled : gearSemiTransparentFilled} />}
        <TextTruncate lines={3}>{reason.name}</TextTruncate>
        {!reason.leaf && (
          <ButtonIcon
            className="arrow-icon"
            width="20px"
            src={selected || isLastColumn ? arrowRightBlackFilled : arrowRightSemiTransparentFilled}
          />
        )}
      </Button>
    );
  },
);

export default ReasonRow;
