import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { Col, Row } from 'styled-bootstrap-grid';

import { Button } from '../Button';
import { Dialog, DialogFooter, DialogMessage } from '../Dialog';

export interface ConfirmationModalProps {
  children?: React.ReactNode;
  isOpen?: boolean;
  title?: string;
  message?: string;
  actionLink?: React.ReactNode;
  rejectButtonText?: string;
  confirmButtonText?: string;
  pending?: boolean;
  onSuccess?: () => void;
  onFailure?: () => void;
  'data-testid'?: string;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  children,
  isOpen,
  title,
  message,
  actionLink,
  rejectButtonText,
  confirmButtonText,
  pending,
  onSuccess,
  onFailure,
  'data-testid': dataTestID,
}): ReactElement => {
  const intl = useIntl();
  return (
    <Dialog open={isOpen} contentPadding="0 32px 0 32px" title={title} data-testid={dataTestID}>
      {message && <DialogMessage>{message}</DialogMessage>}
      {children}
      <DialogFooter>
        <Row alignItems="center" justifyContent="between">
          <Col col="auto" noGutter>
            <Button data-testid="reject-button" size="large" onClick={onFailure}>
              {rejectButtonText ||
                intl.formatMessage({ defaultMessage: 'No', description: 'Confirmation Modal rejection button text' })}
            </Button>
          </Col>
          <Col col="auto" noGutter>
            <Row alignItems="center" noGutter>
              {actionLink && (
                <Col col="auto" noGutter>
                  {actionLink}
                </Col>
              )}
              <Col col="auto" noGutter>
                <Button data-testid="confirm-button" primary size="large" onClick={onSuccess} disabled={pending}>
                  {confirmButtonText ||
                    intl.formatMessage({
                      defaultMessage: 'Yes',
                      description: 'Confirmation Modal confirmation button text',
                    })}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </DialogFooter>
    </Dialog>
  );
};
