/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { CounterKindEnumType } from "./CounterKindEnum"
import { RootStoreType } from "./index"


/**
 * CounterBase
 * auto generated base class for the model CounterModel.
 */
export const CounterModelBase = ModelBase
  .named('Counter')
  .props({
    __typename: types.optional(types.literal("Counter"), "Counter"),
    directoryId: types.union(types.undefined, types.null, types.string),
    hasNote: types.union(types.undefined, types.boolean),
    id: types.identifier,
    isDisabled: types.union(types.undefined, types.boolean),
    kind: types.union(types.undefined, CounterKindEnumType),
    name: types.union(types.undefined, types.string),
    position: types.union(types.undefined, types.integer),
    value: types.union(types.undefined, types.number),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class CounterModelSelector extends QueryBuilder {
  get directoryId() { return this.__attr(`directoryId`) }
  get hasNote() { return this.__attr(`hasNote`) }
  get id() { return this.__attr(`id`) }
  get isDisabled() { return this.__attr(`isDisabled`) }
  get kind() { return this.__attr(`kind`) }
  get name() { return this.__attr(`name`) }
  get position() { return this.__attr(`position`) }
  get value() { return this.__attr(`value`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
}
export function selectFromCounter() {
  return new CounterModelSelector()
}

export const counterModelPrimitives = selectFromCounter().directoryId.hasNote.isDisabled.kind.name.position.value.workplaceId
