import { useIntl } from 'react-intl';

import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import * as Yup from 'yup';

import { FieldError, Textarea } from '@/components/core';

import { Form } from './styled';

interface NoteFormProps {
  children: React.ReactNode;
  ignoreRequired?: boolean;
  onSubmit: (note?: string) => void;
}

const NoteForm: React.FC<NoteFormProps> = observer(({ children, ignoreRequired, onSubmit }) => {
  const intl = useIntl();
  const formProps = useFormik({
    initialValues: {
      content: '',
    },
    validationSchema: Yup.object().shape({
      content: ignoreRequired
        ? Yup.string()
        : Yup.string().required(
            intl.formatMessage({
              defaultMessage: 'This field is required',
              description: 'Note form content required text',
            }),
          ),
    }),
    onSubmit: (values) => {
      onSubmit(values.content);
    },
  });

  return (
    <Form data-testid="note-form" onSubmit={formProps.handleSubmit} autoComplete="off" noValidate>
      <Textarea
        name="content"
        data-testid="content-field"
        rows={5}
        value={formProps.values.content}
        onChange={formProps.handleChange}
        error={formProps.touched.content && Boolean(formProps.errors.content)}
      />
      <FieldError message={formProps.touched.content && formProps.errors.content} data-testid="content-field-error" />
      {children}
    </Form>
  );
});

export default NoteForm;
