import { DialogMessage as StyledDialogMessage, DialogMessageTitle } from './styled';

interface DialogMessageProps {
  children: React.ReactNode;
  title?: string;
  textSmall?: boolean;
  noTopGutter?: boolean;
  'data-testid'?: string;
}

const DialogMessage: React.FC<DialogMessageProps> = ({
  children,
  title,
  textSmall,
  noTopGutter,
  'data-testid': dataTestID,
}) => {
  return (
    <StyledDialogMessage textSmall={textSmall} noTopGutter={noTopGutter} data-testid={dataTestID}>
      {title && <DialogMessageTitle>{title}</DialogMessageTitle>}
      {children}
    </StyledDialogMessage>
  );
};

export default DialogMessage;
