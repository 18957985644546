import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { Col } from 'styled-bootstrap-grid';

import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { AndonLightColor, WorkplaceModelType } from '@/models';

import { StyledItem } from './styled';

interface ListItemProps {
  item: WorkplaceModelType;
}

export const ListItem: React.FC<ListItemProps> = ({ item }): ReactElement => {
  const navigate = useNavigate();

  return (
    <StyledItem
      statusColor={
        item.currentStatusChange &&
        mapStatusColorToColorString(item.currentStatusChange.reason.statusColor as AndonLightColor)
      }
      data-testid={`workplace-select-list-item-${item.id}`}
      onClick={() => {
        navigate(`/workplaces/${item.id}`);
      }}
    >
      <Col col={12}>{item.name}</Col>
    </StyledItem>
  );
};
