import { cast } from 'mobx-state-tree';

import { factory } from '@/factories';
import {
  CompanyConfigModelType,
  DeviceConfigModelType,
  OrderConfigModelType,
  OrderExecutionConfigModelType,
  ProductConfigModelType,
  RoleConfigModelType,
  RootStore,
  RootStoreType,
  ScreenSaverConfigModelType,
  WorkplaceConfigModelType,
} from '@/models';

export const buildDefaultOrderConfig = async ({ store }: { store?: RootStoreType }): Promise<OrderConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<OrderConfigModelType>(
    'OrderConfig',
    {
      alternativeName: false,
    },
    { rootStore },
  );
};

export const buildDefaultOrderExecutionConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<OrderExecutionConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<OrderExecutionConfigModelType>(
    'OrderExecutionConfig',
    {
      featureEnabled: true,
      formOrderFields: cast(['number']),
      formProductFields: cast(['name', 'number']),
      formQuantityFields: cast(['item_count']),
      itemCountUnit: 'szt.',
      stopEnabled: true,
    },
    { rootStore },
  );
};

export const buildDefaultProductConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<ProductConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<ProductConfigModelType>(
    'ProductConfig',
    {
      alternativeName: false,
    },
    { rootStore },
  );
};

export const buildDefaultRoleConfig = async ({ store }: { store?: RootStoreType }): Promise<RoleConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<RoleConfigModelType>(
    'RoleConfig',
    {
      historyVisibilityEnabled: true,
    },
    { rootStore },
  );
};

export const buildDefaultScreenSaverConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<ScreenSaverConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<ScreenSaverConfigModelType>(
    'ScreenSaverConfig',
    {
      idleTime: 100000,
      enabled: true,
      displayAfterOrderCreated: false,
      displayAfterStatusChangeCreated: false,
      autoSignOut: false,
    },
    { rootStore },
  );
};

export const buildDefaultWorkplaceConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<WorkplaceConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<WorkplaceConfigModelType>(
    'WorkplaceConfig',
    {
      workplaceId: '320',
      orderConfig: await buildDefaultOrderConfig({ store: rootStore }),
      orderExecutionConfig: await buildDefaultOrderExecutionConfig({ store: rootStore }),
      productConfig: await buildDefaultProductConfig({ store: rootStore }),
      screenSaverConfig: await buildDefaultScreenSaverConfig({ store: rootStore }),
    },
    { rootStore },
  );
};
export const buildDefaultDeviceConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<DeviceConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<DeviceConfigModelType>(
    'DeviceConfig',
    {
      deviceId: '1',
      name: 'Stanowisko przy oknie',
      defaultWorkplaceId: '320',
    },
    { rootStore },
  );
};
export const buildDefaultCompanyConfig = async ({
  store,
}: {
  store?: RootStoreType;
}): Promise<CompanyConfigModelType> => {
  let rootStore: RootStoreType;

  if (!store) rootStore = RootStore.create({}, {});
  else {
    rootStore = store;
  }
  return factory.build<CompanyConfigModelType>(
    'CompanyConfig',
    {
      roleConfig: await buildDefaultRoleConfig({ store: rootStore }),
      workplacesConfigs: [await buildDefaultWorkplaceConfig({ store: rootStore })],
      displayedKpiItems: cast(['oee', 'performance', 'availability']),
      devicesConfigs: [await buildDefaultDeviceConfig({ store: rootStore })],
    },
    { rootStore },
  );
};
