/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * OrderConfigBase
 * auto generated base class for the model OrderConfigModel.
 */
export const OrderConfigModelBase = ModelBase
  .named('OrderConfig')
  .props({
    __typename: types.optional(types.literal("OrderConfig"), "OrderConfig"),
    alternativeName: types.union(types.undefined, types.boolean),
    numberPrefix: types.union(types.undefined, types.null, types.string),
    numberRegexp: types.union(types.undefined, types.null, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrderConfigModelSelector extends QueryBuilder {
  get alternativeName() { return this.__attr(`alternativeName`) }
  get numberPrefix() { return this.__attr(`numberPrefix`) }
  get numberRegexp() { return this.__attr(`numberRegexp`) }
}
export function selectFromOrderConfig() {
  return new OrderConfigModelSelector()
}

export const orderConfigModelPrimitives = selectFromOrderConfig().alternativeName.numberPrefix.numberRegexp
