import { useEffect, useRef } from 'react';

import { Box } from '../Box';
import { Input, InputProps } from '../Input';

import { TextField as StyledTextField, TextFieldLabel, TextFieldUnit } from './styled';

interface TextFieldProps extends InputProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  label?: string;
  unit?: string;
  width?: string | number;
  error?: boolean;
  'data-testid'?: string;
}

export const TextField: React.FC<TextFieldProps> = ({ inputRef, label, unit, width, error, ...inputProps }) => {
  const innerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputProps.focus) {
      (inputRef || innerRef)?.current?.focus();
    }
  }, [inputRef, inputProps.focus]);

  return (
    <StyledTextField width={width} error={error}>
      {label && <TextFieldLabel className="text-field-label">{label}</TextFieldLabel>}
      <Box>
        <Input ref={inputRef || innerRef} size="large" error={error} {...inputProps} />
        {unit && <TextFieldUnit>{unit}</TextFieldUnit>}
      </Box>
    </StyledTextField>
  );
};
