/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { OrderConfigModel, OrderConfigModelType } from "./OrderConfigModel"
import { OrderConfigModelSelector } from "./OrderConfigModel.base"
import { OrderExecutionConfigModel, OrderExecutionConfigModelType } from "./OrderExecutionConfigModel"
import { OrderExecutionConfigModelSelector } from "./OrderExecutionConfigModel.base"
import { ProductConfigModel, ProductConfigModelType } from "./ProductConfigModel"
import { ProductConfigModelSelector } from "./ProductConfigModel.base"
import { ProductionModeEnumType } from "./ProductionModeEnum"
import { ScreenEnumType } from "./ScreenEnum"
import { ScreenSaverConfigModel, ScreenSaverConfigModelType } from "./ScreenSaverConfigModel"
import { ScreenSaverConfigModelSelector } from "./ScreenSaverConfigModel.base"
import { StatusScreenDisplayBlocksEnumType } from "./StatusScreenDisplayBlocksEnum"
import { RootStoreType } from "./index"


/**
 * WorkplaceConfigBase
 * auto generated base class for the model WorkplaceConfigModel.
 */
export const WorkplaceConfigModelBase = ModelBase
  .named('WorkplaceConfig')
  .props({
    __typename: types.optional(types.literal("WorkplaceConfig"), "WorkplaceConfig"),
    defaultScreen: types.union(types.undefined, ScreenEnumType),
    menuDisplayItems: types.union(types.undefined, types.array(ScreenEnumType)),
    orderConfig: types.union(types.undefined, types.late((): any => OrderConfigModel)),
    orderExecutionConfig: types.union(types.undefined, types.late((): any => OrderExecutionConfigModel)),
    productConfig: types.union(types.undefined, types.late((): any => ProductConfigModel)),
    productionMode: types.union(types.undefined, ProductionModeEnumType),
    screenSaverConfig: types.union(types.undefined, types.late((): any => ScreenSaverConfigModel)),
    statusScreenDisplayBlocks: types.union(types.undefined, types.null, types.array(StatusScreenDisplayBlocksEnumType)),
    workplaceId: types.identifier,
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class WorkplaceConfigModelSelector extends QueryBuilder {
  get defaultScreen() { return this.__attr(`defaultScreen`) }
  get menuDisplayItems() { return this.__attr(`menuDisplayItems`) }
  get productionMode() { return this.__attr(`productionMode`) }
  get statusScreenDisplayBlocks() { return this.__attr(`statusScreenDisplayBlocks`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
  orderConfig(builder: string | OrderConfigModelSelector | ((selector: OrderConfigModelSelector) => OrderConfigModelSelector) | undefined) { return this.__child(`orderConfig`, OrderConfigModelSelector, builder) }
  orderExecutionConfig(builder: string | OrderExecutionConfigModelSelector | ((selector: OrderExecutionConfigModelSelector) => OrderExecutionConfigModelSelector) | undefined) { return this.__child(`orderExecutionConfig`, OrderExecutionConfigModelSelector, builder) }
  productConfig(builder: string | ProductConfigModelSelector | ((selector: ProductConfigModelSelector) => ProductConfigModelSelector) | undefined) { return this.__child(`productConfig`, ProductConfigModelSelector, builder) }
  screenSaverConfig(builder: string | ScreenSaverConfigModelSelector | ((selector: ScreenSaverConfigModelSelector) => ScreenSaverConfigModelSelector) | undefined) { return this.__child(`screenSaverConfig`, ScreenSaverConfigModelSelector, builder) }
}
export function selectFromWorkplaceConfig() {
  return new WorkplaceConfigModelSelector()
}

export const workplaceConfigModelPrimitives = selectFromWorkplaceConfig().defaultScreen.menuDisplayItems.productionMode.statusScreenDisplayBlocks.workplaceId
