import styled, { css } from 'styled-components';

interface TextFieldProps {
  width?: string | number;
  error?: boolean;
}

export const TextField = styled.div<TextFieldProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: ${({ width }) => width || '100%'};
  margin-top: 24px;
  &:focus-within {
    .text-field-label {
      color: ${({ theme }) => theme.colors.white};
    }
  }
  ${(props) =>
    props.error &&
    css`
      .text-field-label {
        color: ${({ theme }) => theme.colors.redOrangeJuice};
      }
      &:focus-within {
        .text-field-label {
          color: ${({ theme }) => theme.colors.redOrangeJuice};
        }
      }
    `}
`;

export const TextFieldLabel = styled.span`
  line-height: 16px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.wolfram};
`;

export const TextFieldUnit = styled.span`
  display: block;
  margin-left: 8px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.wolfram};
  white-space: nowrap;
  &:before {
    content: ' / ';
  }
`;
