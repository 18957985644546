import { FormattedMessage, useIntl } from 'react-intl';

import { Col, Row } from 'styled-bootstrap-grid';

import { Button, Dialog } from '@/components/core';
import { NoteForm } from '@/components/forms';

interface CounterModalProps {
  open: boolean;
  onCancel: () => void;
  onSubmit: (note?: string) => void;
}

const CounterModal: React.FC<CounterModalProps> = ({ open, onCancel, onSubmit }) => {
  const intl = useIntl();

  return (
    <Dialog
      title={intl.formatMessage({
        defaultMessage: 'Counter note',
        description: 'Counter modal title',
      })}
      open={open}
      data-testid="counter-modal"
    >
      <NoteForm onSubmit={onSubmit} ignoreRequired>
        <Row style={{ marginTop: '24px' }}>
          <Col col={6}>
            <Button type="button" size="large" data-testid="counter-modal-cancel-button" onClick={onCancel} fullWidth>
              <FormattedMessage defaultMessage="Cancel" description="Counter modal cancel button" />
            </Button>
          </Col>
          <Col col={6}>
            <Button type="submit" size="large" data-testid="counter-modal-submit-button" primary fullWidth>
              <FormattedMessage defaultMessage="Send" description="Counter modal submit button" />
            </Button>
          </Col>
        </Row>
      </NoteForm>
    </Dialog>
  );
};

export default CounterModal;
