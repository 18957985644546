import styled from 'styled-components';

import { Form } from '../styled';

export const OrderExecutionForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-height: 800px) {
    height: 704px;
  }
`;

export const OrderExecutionFormSteps = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
`;
