import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useLoginStatus } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';
import { v4 as uuidv4 } from 'uuid';

import { OrderExecutionModalView } from '@/components/const';
import { Box, Button, DialogFooter, DialogStep } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { useStartOrderExecution, useStore } from '@/hooks';
import { CompanyConfigModelType, OrderExecutionModel, StartOrderExecutionPayloadModelType, useQuery } from '@/models';
import { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

import { PlannedExecutionSummarySteps } from './styled';

interface PlannedExecutionSummaryViewProps {
  state: OrderExecutionModalState;
  companyConfig: CompanyConfigModelType;
}

const PlannedExecutionSummaryView: React.FC<PlannedExecutionSummaryViewProps> = observer(({ state, companyConfig }) => {
  const { authResponse } = useLoginStatus();
  const rootStore = useStore();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { setQuery: setUpdateOrderQuery } = useQuery();
  const startOrderExecution = useStartOrderExecution(state);
  // use refs to provide current values for callbacks
  const startOrderExecutionRef = useRef(startOrderExecution);
  const [saving, setSaving] = useState<boolean>(false);

  const { selectedOrder, setCurrentView, clearSteps, setOrderExecutionModalOpened } = state;
  const workplaceConfig = getWorkplaceConfig(companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const orderExecutionConfig = workplaceConfig?.orderExecutionConfig;
  const productConfig = workplaceConfig?.productConfig;
  const intl = useIntl();

  useEffect(() => {
    startOrderExecutionRef.current = startOrderExecution;
  }, [startOrderExecution]);

  const getOrderSummaryRecords = () => {
    return [
      {
        value: selectedOrder?.number || '-',
      },
    ];
  };

  const getProductSummaryRecords = () => {
    const selectedItem = selectedOrder?.items?.[0];

    return [
      {
        label: !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Product name',
              description: 'Product summary name record alternative placeholder',
            })
          : intl.formatMessage({
              defaultMessage: 'Product name',
              description: 'Product summary name record placeholder',
            }),
        value: selectedItem?.product.name || '-',
      },
      {
        label: !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Product number',
              description: 'Product summary number record alternative placeholder',
            })
          : intl.formatMessage({
              defaultMessage: 'Product number',
              description: 'Product summary number record placeholder',
            }),
        value: selectedItem?.product.number || '-',
      },
    ];
  };

  const getQuantitySummaryRecords = () => {
    const selectedItem = selectedOrder?.items?.[0];

    return [
      {
        value: selectedItem?.count || '-',
        unit: orderExecutionConfig?.itemCountUnit,
      },
    ];
  };

  const handleBack = () => {
    setCurrentView(OrderExecutionModalView.PLANNED);
  };

  const handleStart = async () => {
    if (authResponse && startOrderExecutionRef.current && selectedOrder) {
      setSaving(true);

      const selectedItem = selectedOrder?.items?.[0];

      const orderExecution = OrderExecutionModel.create({
        id: uuidv4(),
        workplaceId: workplaceID,
        ...(selectedItem && {
          itemCount: selectedItem.count,
        }),
      });
      rootStore.addOrderExecution(orderExecution);

      orderExecution.update({ item: selectedItem, order: selectedOrder, product: selectedItem?.product });

      if (selectedOrder?.workplaces.length === 0) {
        setUpdateOrderQuery((store) =>
          store.mutateUpdateOrder({
            input: {
              id: selectedOrder.id,
              workplaceIds: [workplaceID],
            },
          }),
        );
      }
      const query = startOrderExecutionRef.current(orderExecution);

      let startOrderExecutionResponse;

      const { startOrderExecution } = (await query.currentPromise()) as {
        startOrderExecution: StartOrderExecutionPayloadModelType;
      };
      startOrderExecutionResponse = startOrderExecution;

      if (startOrderExecutionResponse.errors) {
        const nonFieldError = startOrderExecutionResponse.errors.find((error) => error.field === 'base');

        if (nonFieldError) {
          return null;
        }
        // TODO error handling
      } else {
        clearSteps();

        setSaving(false);

        setOrderExecutionModalOpened(false);
      }
    }
  };

  return (
    <Box display="block" data-testid="planned-execution-summary-view" style={{ height: '100%' }}>
      <PlannedExecutionSummarySteps>
        <DialogStep
          editable={false}
          step={1}
          title={
            !!orderConfig?.alternativeName
              ? intl.formatMessage({
                  defaultMessage: 'Order',
                  description: 'Order step alternative title',
                })
              : intl.formatMessage({
                  defaultMessage: 'Order',
                  description: 'Order step title',
                })
          }
          summaryRecords={getOrderSummaryRecords()}
          completed
        />
        <DialogStep
          editable={false}
          step={2}
          title={
            !!productConfig?.alternativeName
              ? intl.formatMessage({
                  defaultMessage: 'Product',
                  description: 'Product step alternative title',
                })
              : intl.formatMessage({
                  defaultMessage: 'Product',
                  description: 'Product step title',
                })
          }
          summaryRecords={getProductSummaryRecords()}
          completed
        />
        <DialogStep
          editable={false}
          step={3}
          title={
            !!productConfig?.alternativeName
              ? intl.formatMessage({
                  defaultMessage: 'Planned number of products',
                  description: 'Quantity step alternative title',
                })
              : intl.formatMessage({
                  defaultMessage: 'Planned number of products',
                  description: 'Quantity step title',
                })
          }
          summaryRecords={getQuantitySummaryRecords()}
          completed
        />
      </PlannedExecutionSummarySteps>
      <DialogFooter>
        <Row justifyContent="between">
          <Col col="auto" noGutter>
            <Button
              type="button"
              size="large"
              onClick={handleBack}
              data-testid="planned-execution-summary-view-back-button"
            >
              <FormattedMessage defaultMessage="Back" description="Planned execution summary view back button" />
            </Button>
          </Col>
          <Col col="auto" noGutter>
            <Button
              type="button"
              size="large"
              onClick={handleStart}
              data-testid="planned-execution-summary-view-start-button"
              disabled={saving}
              primary
            >
              <FormattedMessage defaultMessage="Start" description="Planned execution summary view start button" />
            </Button>
          </Col>
        </Row>
      </DialogFooter>
    </Box>
  );
});

export default PlannedExecutionSummaryView;
