import { createOrderPayloadModelPrimitives } from '@/models/CreateOrderPayloadModel.base';
import { itemModelPrimitives } from '@/models/ItemModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { orderModelPrimitives, selectFromOrder } from '@/models/OrderModel.base';
import { productModelPrimitives } from '@/models/ProductModel.base';
import { workplaceModelPrimitives } from '@/models/WorkplaceModel.base';

export default {
  ALL: orderModelPrimitives
    .items(itemModelPrimitives.product(productModelPrimitives))
    .workplaces(workplaceModelPrimitives)
    .toString(),
  AUTOCOMPLETE: selectFromOrder()
    .number.plannedCount.position.startedAt.finishedAt.items((item) => item.id.count.product(productModelPrimitives))
    .workplaces((workplace) => workplace.id.name.deactivated)
    .toString(),
  CREATE_ORDER_PAYLOAD_ALL: createOrderPayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .order(orderModelPrimitives)
    .toString(),
};
