import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import AutocompleteBase from 'react-autocomplete';

import { observer } from 'mobx-react-lite';

import { IconButton, TextField } from '@/components/core';
import enterIcon from '@/images/enter.svg';
import plusIcon from '@/images/plusImage.svg';
import searchIcon from '@/images/searchIcon.svg';
import xGrayIcon from '@/images/xGrayIcon.svg';

import { Loader } from '../Loader';

import { Item, Menu, MenuTitle } from './styled';

interface AutocompleteProps extends Omit<AutocompleteBase.Props, 'getItemValue' | 'renderItem' | 'value'> {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  createButtonText?: string;
  menuTitle?: string;
  itemIdKey?: string;
  itemLabelKey: string;
  itemSearchKeys?: string[];
  ignoreCase?: boolean;
  prefix?: string | null;
  initialValue?: string;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onCreate?: () => void;
  onClear?: () => void;
  error?: boolean;
  focus?: boolean;
  hideSearchIcon?: boolean;
  hideClear?: boolean;
  disableClear?: boolean;
  emptyMessage?: React.ReactNode;
  disabled?: boolean;
  pending?: boolean;
  width?: string | number;
  'data-testid'?: string;
}

export const Autocomplete: React.FC<AutocompleteProps> = observer((props) => {
  const {
    name,
    label,
    placeholder,
    createButtonText,
    menuTitle,
    itemIdKey = 'id',
    itemLabelKey,
    itemSearchKeys,
    ignoreCase = true,
    items,
    prefix,
    initialValue = '',
    onKeyDown,
    onChange,
    onSelect,
    onFocus,
    onBlur,
    onCreate,
    onClear,
    error,
    focus,
    hideSearchIcon,
    hideClear,
    disableClear,
    emptyMessage,
    disabled,
    pending,
    width,
    'data-testid': dataTestID,
  } = props;

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(initialValue);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const clearInput = () => {
    if (onClear && onClear instanceof Function) {
      onClear();
    }
    if (!hideClear) {
      setShowClearButton(false);
    }
    inputRef.current?.focus();
  };

  const getItemValue = (item: Record<string, unknown>) => {
    const itemLabelKeys = itemLabelKey.split('|');
    const itemValue = item[
      itemLabelKeys.find((key) => (item[key] as string)?.length > 0) || itemLabelKeys[0]
    ] as string;

    return itemValue.replace(prefix || '', '');
  };

  const renderItemValue = (item: Record<string, unknown>) => {
    const itemValue = getItemValue(item);
    const matchIndex = itemValue.indexOf(inputValue);
    const beforeMatch = itemValue.substring(0, matchIndex);
    const match = itemValue.substring(matchIndex, matchIndex + inputValue.length);
    const afterMatch = itemValue.substring(matchIndex + inputValue.length);

    return (
      <>
        {beforeMatch}
        <span>{match}</span>
        {afterMatch}
      </>
    );
  };

  const isSelected = useCallback(
    (item: Record<string, unknown>, value?: string) => {
      return (itemSearchKeys || itemLabelKey.split('|')).some((key) => {
        const itemValue = item[key] as string;

        if (ignoreCase) {
          return itemValue.toLowerCase() === (value || inputValue).toLowerCase();
        }
        return itemValue === value || itemValue === inputValue;
      });
    },
    [ignoreCase, itemLabelKey, itemSearchKeys, inputValue],
  );

  const onSelectCallback = (value: string, item: Record<string, unknown>) => {
    if (onSelect && onSelect instanceof Function) {
      onSelect(value, item);
    }
    if (!hideClear) {
      setShowClearButton(true);
    }
    setMenuOpen(false);
  };

  const handleOnCreate = () => {
    if (onCreate && onCreate instanceof Function) {
      onCreate();
    }
  };

  const shouldItemRender = useCallback(
    (item: Record<string, unknown>, value: string) => {
      return (itemSearchKeys || itemLabelKey.split('|')).some((key) => {
        const itemValue = (item[key] as string) || '';

        if (ignoreCase) {
          return itemValue.toLowerCase().indexOf(value.toLowerCase()) > -1;
        }
        return itemValue.indexOf(value) > -1;
      });
    },
    [ignoreCase, itemLabelKey, itemSearchKeys],
  );

  useEffect(() => {
    if (
      inputRef.current === document.activeElement &&
      inputValue.length > 1 &&
      items.some((item) => shouldItemRender(item, inputValue))
    ) {
      let hasSelectedItem = false;

      items.forEach((item) => {
        if (isSelected(item, inputValue)) {
          const renderedItemsCount = items.filter((item) => shouldItemRender(item, inputValue)).length;

          if (renderedItemsCount === 1) {
            hasSelectedItem = true;
          }
        }
      });
      if (!hasSelectedItem) {
        setMenuOpen(true);
      }
    } else {
      setMenuOpen(false);
    }
  }, [items, inputValue, shouldItemRender, isSelected]);

  const handleOnBlur = () => {
    items.forEach((item) => {
      if (isSelected(item, inputValue)) {
        const renderedItemsCount = items.filter((item) => shouldItemRender(item, inputValue)).length;

        if (renderedItemsCount === 1) {
          onSelectCallback(inputValue, item);
        }
      }
    });
    if (onBlur && onBlur instanceof Function) {
      onBlur();
    }
    setMenuOpen(false);
  };

  const renderLoader = () => <Loader width="40px" />;

  const renderCreateButton = () => {
    const inputHasTypedValue = inputValue !== '';

    return (
      createButtonText && (
        <IconButton
          text={inputHasTypedValue ? 'Enter' : createButtonText}
          src={inputHasTypedValue ? enterIcon : plusIcon}
          onClick={() => handleOnCreate()}
          data-testid="autocomplete-create-button"
        />
      )
    );
  };

  const renderClearButton = () => (
    <IconButton src={xGrayIcon} onClick={clearInput} disabled={disableClear} data-testid="autocomplete-clear-button" />
  );

  return (
    <AutocompleteBase
      {...props}
      open={menuOpen}
      value={inputValue}
      getItemValue={(item: Record<string, unknown>) => getItemValue(item)}
      onChange={(e, value) => {
        setInputValue(value);

        if (onChange && onChange instanceof Function) {
          onChange(e, value);
        }
      }}
      onSelect={(value, item) => {
        setInputValue(value);

        onSelectCallback(value, item);
      }}
      wrapperStyle={{ display: 'inline-block', position: 'relative' }}
      renderMenu={(items) =>
        items.length ? (
          <Menu data-testid={`${dataTestID || 'autocomplete'}-menu`}>
            {menuTitle && <MenuTitle>{menuTitle}:</MenuTitle>}
            {items.slice(0, 5)}
          </Menu>
        ) : (
          <Menu>
            {menuTitle && <MenuTitle>{menuTitle}:</MenuTitle>}
            {emptyMessage}
          </Menu>
        )
      }
      shouldItemRender={shouldItemRender}
      renderItem={(item: Record<string, unknown>, isHighlighted: boolean) => (
        <Item key={item[itemIdKey] as string} isHighlighted={isHighlighted} isSelected={isSelected(item)}>
          {renderItemValue(item)}
        </Item>
      )}
      inputProps={{
        placeholder,
        onFocus,
        onBlur: handleOnBlur,
        disabled: disabled || pending,
        readOnly: showClearButton,
      }}
      renderInput={(inputProps) => {
        // initialize inner input reference with same element
        (inputProps.ref as React.RefCallback<HTMLInputElement>)(inputRef.current);

        return (
          <TextField
            {...inputProps}
            size="large"
            inputRef={inputRef}
            name={name}
            label={label}
            width={width}
            focus={focus}
            error={error}
            onKeyDown={onKeyDown || inputProps.onKeyDown}
            startAdornment={!hideSearchIcon && <img src={searchIcon} alt="🔍" />}
            endAdornment={pending ? renderLoader() : showClearButton ? renderClearButton() : renderCreateButton()}
            endAdornmentSize="small"
            data-testid={dataTestID}
          />
        );
      }}
      /*
      open={menuOpened}
      */
    />
  );
});
