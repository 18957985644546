import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { Button, DialogFooter, DialogMessage } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { ReasonsParamTypes } from '@/types';

import { OrderExecutionModalProps } from '../..';

const NoPermissionsView: React.FC<OrderExecutionModalProps> = observer(({ state, companyConfig }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const intl = useIntl();

  const workplaceConfig = getWorkplaceConfig(companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const { setOrderExecutionModalOpened } = state;

  const handleConfimAction = () => {
    setOrderExecutionModalOpened(false);
  };

  const renderNoPermissionsMessage = () => {
    return !!orderConfig?.alternativeName ? (
      <FormattedMessage
        defaultMessage="You do not have permission to create orders. Contact your supervisor."
        description="Order execution modal no permissions view alternative message"
      />
    ) : (
      <FormattedMessage
        defaultMessage="You do not have permission to create orders. Contact your supervisor."
        description="Order execution modal no permissions view message"
      />
    );
  };
  return (
    <Box display="block" data-testid="no-permissions-view">
      <DialogMessage
        title={intl.formatMessage({
          defaultMessage: 'No permissions',
          description: 'Order execution modal no permissions view title',
        })}
        noTopGutter
      >
        {renderNoPermissionsMessage()}
      </DialogMessage>
      <DialogFooter>
        <Row justifyContent="end">
          <Col col="auto" noGutter>
            <Button
              type="button"
              size="large"
              onClick={handleConfimAction}
              data-testid="no-permissions-view-confirm-button"
              primary
            >
              <FormattedMessage defaultMessage="I Understand" description="No permissions view confirm button" />
            </Button>
          </Col>
        </Row>
      </DialogFooter>
    </Box>
  );
});

export default NoPermissionsView;
