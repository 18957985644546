import styled, { css } from 'styled-components';

export interface InputProps {
  size?: 'small' | 'large';
  error?: boolean;
  gridArea?: string;
}

export interface InputAdornmentProps {
  position?: 'start' | 'end';
  size?: 'small' | 'large';
  visible?: boolean;
}

export const Input = styled.div<InputProps>`
  display: flex;
  width: 100%;
  padding: ${({ size }) => (size === 'small' ? '0.75em' : '24px 16px')};
  border: ${({ theme }) => `1px solid ${theme.colors.thunder}`};
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  &:focus-within {
    border-color: ${({ theme }) => theme.colors.dodgerBlue};
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${({ theme }) => theme.colors.redOrangeJuice};
      &:focus-within {
        border-color: ${({ theme }) => theme.colors.redOrangeJuice};
      }
    `}
  ${({ gridArea }) =>
    gridArea &&
    css`
      grid-area: ${gridArea};
    `}
`;

export const InputField = styled.input`
  appearance: none;
  width: 100%;
  font-size: 1em;
  color: ${({ theme }) => theme.colors.white};
  outline: none;
  border: none;
  background: transparent;
  &:disabled {
    color: rgba(255, 255, 255, 0.5);
  }
  &:read-only {
    pointer-events: none;
  }
`;

export const InputAdornment = styled.div<InputAdornmentProps>`
  display: flex;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  min-width: 1.25em;
  height: 1.25em;
  align-items: center;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.5);
  ${(props) =>
    props.position === 'end'
      ? css`
          margin-left: 0.5em;
        `
      : css`
          margin-right: 0.5em;
        `}
  ${({ size }) =>
    size === 'large' &&
    css`
      min-width: 5em;
    `}
`;
