import { IntlShape } from 'react-intl';

import { Button, ProgressBar } from '@/components/core';
import { OrderConfigModelType, OrderExecutionModelType, ProductConfigModelType } from '@/models';
import { ListItem } from '@/types';

import { getProductDisplayName } from './products';

interface ICreateOrderExecutionListItems {
  orderConfig: OrderConfigModelType;
  productConfig: ProductConfigModelType;
  orderExecution: OrderExecutionModelType;
  intl: IntlShape;
}

const renderProgressBar = (orderExecution: OrderExecutionModelType) => {
  const executedCount = orderExecution.item.order.executedCount || 0;
  const plannedCount = orderExecution.item.order.plannedCount || 0;

  return (
    <ProgressBar
      completed={(executedCount / plannedCount) * 100}
      customLabel={executedCount.toString()}
      customLabelStyles={executedCount < 5 ? { position: 'absolute', left: '-10px' } : {}}
      maxLabel={plannedCount}
    />
  );
};

export const createOrderExecutionListItems = ({
  orderConfig,
  productConfig,
  orderExecution,
  intl,
}: ICreateOrderExecutionListItems): ListItem[] => {
  const list: ListItem[] = [];
  list.push({
    label: !!orderConfig?.alternativeName
      ? intl.formatMessage({
          defaultMessage: 'Order number',
          description: 'Order info number alternative label',
        })
      : intl.formatMessage({
          defaultMessage: 'Order number',
          description: 'Order info number label',
        }),
    value: orderExecution.order.number,
    dataTestId: 'order-info-row-order-number',
  });
  if (orderExecution.product) {
    list.push({
      label: !!productConfig?.alternativeName
        ? intl.formatMessage({
            defaultMessage: 'Product',
            description: 'Order info product alternative label',
          })
        : intl.formatMessage({
            defaultMessage: 'Product',
            description: 'Order info product label',
          }),
      value: getProductDisplayName(orderExecution.product),
      dataTestId: 'order-info-row-product',
    });
  }
  if (orderExecution.item?.order?.plannedCount) {
    list.push({
      label: !!orderConfig?.alternativeName
        ? intl.formatMessage({
            defaultMessage: 'Order progress',
            description: 'Order info order progress alternative label',
          })
        : intl.formatMessage({
            defaultMessage: 'Order progress',
            description: 'Order info order progress label',
          }),
      value: renderProgressBar(orderExecution),
      dataTestId: 'order-info-executed-count',
    });
  }
  if (orderExecution.item?.order?.note) {
    list.push({
      label: !!productConfig?.alternativeName
        ? intl.formatMessage({
            defaultMessage: 'Order note',
            description: 'Order info note alternative label',
          })
        : intl.formatMessage({
            defaultMessage: 'Order note',
            description: 'Order info note label',
          }),
      value: orderExecution.item.order.note,
      className: 'auto-height',
      dataTestId: 'order-info-note',
    });
  }
  if (orderExecution.product?.assemblyManual) {
    list.push({
      dataTestId: 'order-info-row-product-assembly-manual',
      label: !!productConfig?.alternativeName
        ? intl.formatMessage({
            defaultMessage: 'Product manual',
            description: 'Order info product manual link alternative text',
          })
        : intl.formatMessage({
            defaultMessage: 'Product manual',
            description: 'Order info product manual link',
          }),
      value: (
        <Button
          primary
          size="small"
          style={{ margin: '5px 0px 5px 0', width: '100%' }}
          onClick={() => {
            window.open(orderExecution.product.assemblyManual as string, '_blank');
          }}
        >
          {intl.formatMessage({
            defaultMessage: 'Open in a new tab',
            description: 'Order info row manual open button text',
          })}
        </Button>
      ),
    });
  }

  return list;
};
