export enum CounterOperation {
  ADD,
  SUBTRACT,
  SET,
}

export enum OrderExecutionModalView {
  MANUAL = 'manual',
  PLANNED = 'planned',
  PLANNED_SUMMARY = 'plannedSummary',
  NO_PERMISSIONS = 'noPermissions',
  NO_PLAN = 'noPlan',
}

export enum OrderExecutionFormStep {
  ORDER = 'order',
  PRODUCT = 'product',
  QUANTITY = 'quantity',
}
