import styled from 'styled-components';

export const PlannedExecutionSummarySteps = styled.div`
  height: 608px;
  @media (max-height: 800px) {
    height: calc(100% - 96px);
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
