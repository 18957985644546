/* eslint-disable promise/always-return, promise/catch-or-return */
import type { UserData } from 'andoncloud-sdk';
import { Instance, types } from 'mobx-state-tree';
import moment from 'moment';
import type { Query } from 'mst-gql';
import { v4 as uuidv4 } from 'uuid';

import { getMutationErrorText, handleQueryError, isValidationError } from '@/helpers/errors';
import { ReasonModelType, StatusChangeModel, StatusChangeModelType, UserModel } from '@/models';
import { ordersExecutionsQS } from '@/queries';

import { OrderExecutionModelBase } from './OrderExecutionModel.base';
import { DatePrimitive } from './types';

/* The TypeScript type of an instance of OrderExecutionModel */
export interface OrderExecutionModelType extends Instance<typeof OrderExecutionModel.Type> {}

/* A graphql query fragment builders for OrderExecutionModel */
export {
  selectFromOrderExecution,
  orderExecutionModelPrimitives,
  OrderExecutionModelSelector,
} from './OrderExecutionModel.base';

export interface OrderExecutionMutationProps {
  workplaceId: string;
  userData: UserData;
  lastStatusChange: StatusChangeModelType | null;
  reason: ReasonModelType | null;
  optimisticUpdate?: (statusChange: StatusChangeModelType) => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string, isValidationError?: boolean) => void;
}

interface OrderExecutionUpdateMutationProps {
  standardRateId: string;
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

/**
 * OrderExecutionModel
 */
export const OrderExecutionModel = OrderExecutionModelBase.props({
  startedAt: types.union(types.undefined, DatePrimitive),
  finishedAt: types.union(types.undefined, types.null, DatePrimitive),
}).actions((self) => ({
  start({
    workplaceId,
    userData,
    lastStatusChange,
    reason,
    optimisticUpdate,
    revertUpdate,
    onSuccess,
    onError,
  }: OrderExecutionMutationProps): Query {
    const prevStatusChangeFinishedAt = lastStatusChange?.finishedAt?.clone();

    const author = UserModel.create({
      id: userData.id.toString(),
      login: userData.email,
      name: userData.name,
    });

    const statusChange = StatusChangeModel.create({
      id: uuidv4(),
      workplaceId,
      startedAt: moment().toISOString(),
      note: self.note,
    });

    const query = self.store.mutateStartOrderExecution(
      {
        id: self.id,
        workplaceId,
        order: {
          id: self.order.id,
          number: self.order.number || '',
        },
        ...(self.product && {
          product: {
            id: self.product.id,
            name: self.product.name,
            number: self.product.number,
          },
        }),
        statusChangeId: statusChange.id,
        itemCount: self.itemCount as number | undefined,
      },
      ordersExecutionsQS.START_ORDER_EXECUTION_PAYLOAD_ALL,
      () => {
        self.update({ startedAt: statusChange.startedAt });

        lastStatusChange?.update({ finishedAt: statusChange.startedAt });

        statusChange.update({
          author,
          reason: reason || lastStatusChange?.reason,
          order: self.order,
          product: self.product,
        });

        self.store.addStatusChange(statusChange);

        if (optimisticUpdate) optimisticUpdate(statusChange);
      },
    );
    const revert = () => {
      if (revertUpdate) revertUpdate();

      self.store.removeStatusChange(statusChange);

      lastStatusChange?.update({ finishedAt: prevStatusChangeFinishedAt });
    };
    query.then(
      ({ startOrderExecution }) => {
        const { errors } = startOrderExecution;

        if (errors?.length) {
          if (onError) onError(getMutationErrorText(errors[0]), isValidationError(errors[0]));
          revert();
          return;
        }
        if (onSuccess) onSuccess();
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
  pause({
    workplaceId,
    userData,
    lastStatusChange,
    reason,
    optimisticUpdate,
    revertUpdate,
    onSuccess,
    onError,
  }: OrderExecutionMutationProps): Query {
    const prevStatusChangeFinishedAt = lastStatusChange?.finishedAt?.clone();

    const author = UserModel.create({
      id: userData.id.toString(),
      login: userData.email,
      name: userData.name,
    });

    const statusChange = StatusChangeModel.create({
      id: uuidv4(),
      workplaceId,
      startedAt: moment().toISOString(),
    });

    const query = self.store.mutatePauseOrderExecution(
      { id: self.id, statusChangeId: statusChange.id },
      ordersExecutionsQS.PAUSE_ORDER_EXECUTION_PAYLOAD_ALL,
      () => {
        self.update({ finishedAt: statusChange.startedAt });

        lastStatusChange?.update({ finishedAt: statusChange.startedAt });

        statusChange.update({ author, reason });

        self.store.addStatusChange(statusChange);

        if (optimisticUpdate) optimisticUpdate(statusChange);
      },
    );
    const revert = () => {
      if (revertUpdate) revertUpdate();

      self.finishedAt = null;
      self.store.removeStatusChange(statusChange);

      lastStatusChange?.update({ finishedAt: prevStatusChangeFinishedAt });
    };
    query.then(
      ({ pauseOrderExecution }) => {
        const { errors } = pauseOrderExecution;

        if (errors?.length) {
          if (onError) onError(getMutationErrorText(errors[0]));
          revert();
          return;
        }
        if (onSuccess) onSuccess();
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
  stop({
    workplaceId,
    userData,
    lastStatusChange,
    reason,
    optimisticUpdate,
    revertUpdate,
    onSuccess,
    onError,
  }: OrderExecutionMutationProps): Query {
    const prevStatusChangeFinishedAt = lastStatusChange?.finishedAt?.clone();

    const author = UserModel.create({
      id: userData.id.toString(),
      login: userData.email,
      name: userData.name,
    });

    const statusChange = StatusChangeModel.create({
      id: uuidv4(),
      workplaceId,
      startedAt: moment().toISOString(),
    });

    const query = self.store.mutateStopOrderExecution(
      { id: self.id, statusChangeId: statusChange.id },
      ordersExecutionsQS.STOP_ORDER_EXECUTION_PAYLOAD_ALL,
      () => {
        self.update({ finishedAt: statusChange.startedAt });

        lastStatusChange?.update({ finishedAt: statusChange.startedAt });

        statusChange.update({ author, reason });

        self.store.addStatusChange(statusChange);

        if (optimisticUpdate) optimisticUpdate(statusChange);
      },
    );
    const revert = () => {
      if (revertUpdate) revertUpdate();

      self.finishedAt = null;
      self.store.removeStatusChange(statusChange);

      lastStatusChange?.update({ finishedAt: prevStatusChangeFinishedAt });
    };
    query.then(
      ({ stopOrderExecution }) => {
        const { errors } = stopOrderExecution;

        if (errors?.length) {
          if (onError) onError(getMutationErrorText(errors[0]));
          revert();
          return;
        }
        if (onSuccess) onSuccess();
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
  updateStandardRate({
    standardRateId,
    optimisticUpdate,
    revertUpdate,
    onSuccess,
    onError,
  }: OrderExecutionUpdateMutationProps): Query {
    const query = self.store.mutateUpdateOrderExecution(
      { id: self.id, standardRateId },
      ordersExecutionsQS.UPDATE_ORDER_EXECUTION_PAYLOAD_ALL,
      () => {
        self.standardRateMismatched = false;

        if (optimisticUpdate) optimisticUpdate();
      },
    );
    const revert = () => {
      if (revertUpdate) revertUpdate();

      self.standardRateMismatched = true;
    };
    query.then(
      ({ updateOrderExecution }) => {
        const { errors } = updateOrderExecution;

        if (errors?.length) {
          if (onError) onError(getMutationErrorText(errors[0]));
          revert();
          return;
        }
        if (onSuccess) onSuccess();
      },
      (error) => {
        handleQueryError(error, onError);
        revert();
      },
    );
    return query;
  },
}));
