import exclamationIcon from '@/images/exclamation.svg';

import { FieldError as StyledFieldError, FieldErrorIcon } from './styled';

interface FieldErrorProps {
  message?: string | false;
  'data-testid'?: string;
}

export const FieldError: React.FC<FieldErrorProps> = ({ message, 'data-testid': dataTestID }) => {
  return (
    message && (
      <StyledFieldError data-testid={dataTestID}>
        <FieldErrorIcon src={exclamationIcon} />
        {message}
      </StyledFieldError>
    )
  );
};
