import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { useLoginStatus } from 'andoncloud-sdk';
import { FormikConfig, useFormik } from 'formik';
import { DebouncedFunc } from 'lodash';
import camelCase from 'lodash.camelcase';
import debounce from 'lodash.debounce';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { OrderExecutionFormStep } from '@/components/const';
import { formatECMAScriptRegexp } from '@/helpers/formatECMAScriptRegexp';
import { getPrefixedNumber } from '@/helpers/orders-executions';
import { useStartOrderExecution, useStore } from '@/hooks';
import {
  OrderConfigModelType,
  OrderExecutionConfigModelType,
  OrderExecutionModel,
  OrderModel,
  OrderModelType,
  ProductConfigModelType,
  ProductModel,
  ProductModelType,
  StartOrderExecutionPayloadModelType,
  useQuery,
} from '@/models';
import { ordersQS, productsQS } from '@/queries';
import { OrderExecutionFormValues, OrderExecutionModalState } from '@/types';

type ValidateFunc = NonNullable<FormikConfig<OrderExecutionFormValues>['validate']>;

const useOrderExecutionForm = (
  workplaceID: string,
  orderExecutionModalState: OrderExecutionModalState,
  orderConfig: OrderConfigModelType,
  orderExecutionConfig: OrderExecutionConfigModelType,
  productConfig: ProductConfigModelType,
) => {
  const { authResponse } = useLoginStatus();
  const rootStore = useStore();
  const { setQuery: setCheckIfOrderExistsQuery } = useQuery<{
    orders: OrderModelType[];
  }>();
  const { setQuery: setCheckIfProductExistsQuery } = useQuery<{
    products: ProductModelType[];
  }>();
  const startOrderExecution = useStartOrderExecution(orderExecutionModalState);
  // use refs to provide current values for callbacks
  const startOrderExecutionRef = useRef(startOrderExecution);
  const debouncedValidateRef = useRef<DebouncedFunc<ValidateFunc>>();
  const prevValuesRef = useRef<OrderExecutionFormValues>();
  const [saving, setSaving] = useState<boolean>(false);
  const intl = useIntl();
  const {
    setFormProps,
    setExistingOrdersList,
    setValidating,
    orderNumberPrefix,
    selectedProduct,
    setSelectedProduct,
    addStartedOrder,
    setSelectedOrder,
    productNumberPrefix,
    setSteps,
    activeStep,
    clearSteps,
    fieldsNames,
    setFieldsNames,
  } = orderExecutionModalState;
  const getOrderInitialValues = () => {
    return {
      [OrderExecutionFormStep.ORDER]: {
        ...(orderNumberPrefix && { numberPrefix: orderNumberPrefix }),
        number: '',
      },
    };
  };
  const getProductInitialValues = () => {
    return {
      [OrderExecutionFormStep.PRODUCT]: {
        ...(fieldsNames.includes(`${OrderExecutionFormStep.PRODUCT}.name`) && { name: '' }),
        ...(productNumberPrefix && { numberPrefix: productNumberPrefix }),
        ...(fieldsNames.includes(`${OrderExecutionFormStep.PRODUCT}.number`) && { number: '' }),
      },
    };
  };
  const getQuantityInitialValues = () => {
    return {
      [OrderExecutionFormStep.QUANTITY]: {
        ...(fieldsNames.includes(`${OrderExecutionFormStep.QUANTITY}.itemCount`) && { itemCount: '' }),
      },
    };
  };
  const getOrdersValidationSchema = () => {
    return {
      [OrderExecutionFormStep.ORDER]: Yup.object().shape({
        ...(orderNumberPrefix && { numberPrefix: Yup.string() }),
        number: Yup.string().required(
          intl.formatMessage({
            defaultMessage: 'This field is required',
            description: 'Form validation error message for required field',
          }),
        ),
      }),
    };
  };
  const getProductValidationSchema = () => {
    const schema = {
      ...(fieldsNames.includes(`${OrderExecutionFormStep.PRODUCT}.name`) && {
        name: Yup.string().required(
          intl.formatMessage({
            defaultMessage: 'This field is required',
            description: 'Form validation error message for required field',
          }),
        ),
      }),
      ...(productNumberPrefix && { numberPrefix: Yup.string() }),
      ...(fieldsNames.includes(`${OrderExecutionFormStep.PRODUCT}.number`) && {
        number: Yup.string().required(
          intl.formatMessage({
            defaultMessage: 'This field is required',
            description: 'Form validation error message for required field',
          }),
        ),
      }),
    };
    return Object.keys(schema).length ? { [OrderExecutionFormStep.PRODUCT]: Yup.object().shape(schema) } : null;
  };
  const getQuantityValidationSchema = () => {
    const schema = {
      ...(fieldsNames.includes(`${OrderExecutionFormStep.QUANTITY}.itemCount`) && {
        itemCount: Yup.number()
          .typeError(
            intl.formatMessage({
              defaultMessage: 'The value provided is not valid',
              description: 'Form validation error message for invalid value',
            }),
          )
          .required(
            intl.formatMessage({
              defaultMessage: 'This field is required',
              description: 'Form validation error message for required field',
            }),
          ),
      }),
    };
    return Object.keys(schema).length ? { [OrderExecutionFormStep.QUANTITY]: Yup.object().shape(schema) } : null;
  };
  const validate: ValidateFunc = useCallback(
    async (values: OrderExecutionFormValues) => {
      if (activeStep === OrderExecutionFormStep.ORDER) {
        if (values.order.number) {
          const fieldRegexp = formatECMAScriptRegexp(orderConfig.numberRegexp || '');
          const prefixedNumber = getPrefixedNumber(values.order.number, values.order.numberPrefix);

          if (fieldRegexp && !RegExp(fieldRegexp).exec(prefixedNumber)) {
            setValidating(false);

            return {
              order: {
                number: intl.formatMessage({
                  defaultMessage: 'The value provided is not valid',
                  description: 'Form validation error message for invalid value',
                }),
              },
            };
          }
          setCheckIfOrderExistsQuery((store) => {
            const query = store.queryOrders(
              { filter: { order_number_contains: prefixedNumber } },
              ordersQS.AUTOCOMPLETE,
            );

            // eslint-disable-next-line promise/catch-or-return
            query.then((response) => {
              if (response?.orders.length) {
                setExistingOrdersList(response.orders);

                if (response.orders.length === 1 && response.orders[0].number === prefixedNumber) {
                  const existingOrder = response.orders[0];

                  setSelectedOrder(existingOrder);
                }
              } else {
                setSelectedOrder(null);
              }
              setValidating(false);

              return null;
            });
            return query;
          });
        } else {
          setSelectedOrder(null);
        }
      } else if (activeStep === OrderExecutionFormStep.PRODUCT) {
        if (values.product?.number) {
          const fieldRegexp = formatECMAScriptRegexp(productConfig.numberRegexp || '');
          const prefixedNumber = getPrefixedNumber(values.product.number, values.product.numberPrefix);

          if (fieldRegexp && !RegExp(fieldRegexp).exec(prefixedNumber)) {
            setValidating(false);

            return {
              product: {
                number: intl.formatMessage({
                  defaultMessage: 'The value provided is not valid',
                  description: 'Form validation error message for invalid value',
                }),
              },
            };
          }
          setCheckIfProductExistsQuery((store) => {
            const query = store.queryProducts({ filter: { number_equals: prefixedNumber } }, productsQS.AUTOCOMPLETE);

            // eslint-disable-next-line promise/catch-or-return
            query.then((response) => {
              if (response?.products.length) {
                const existingProduct = response.products[0];

                setSelectedProduct(existingProduct);
              } else {
                setSelectedProduct(null);
              }
              setValidating(false);

              return null;
            });

            return query;
          });
        }
      }
      return {};
    },
    [
      orderConfig,
      productConfig,
      activeStep,
      setCheckIfOrderExistsQuery,
      setCheckIfProductExistsQuery,
      setExistingOrdersList,
      setSelectedOrder,
      setSelectedProduct,
      setValidating,
      intl,
    ],
  );

  const formProps = useFormik({
    initialValues: {
      ...getOrderInitialValues(),
      ...getProductInitialValues(),
      ...getQuantityInitialValues(),
    },
    validationSchema: Yup.object().shape({
      ...getOrdersValidationSchema(),
      ...getProductValidationSchema(),
      ...getQuantityValidationSchema(),
    }),
    validate: (values) => {
      if (debouncedValidateRef.current) {
        setValidating(
          prevValuesRef.current?.order.number !== values.order.number ||
            prevValuesRef.current?.product.number !== values.product.number,
        );
        prevValuesRef.current = values;

        return debouncedValidateRef.current(values);
      }
      prevValuesRef.current = values;
    },
    onSubmit: async (values) => {
      if (authResponse && startOrderExecutionRef.current) {
        const { setOrderExecutionModalOpened } = orderExecutionModalState;

        setSaving(true);

        const orderExecution = OrderExecutionModel.create({
          id: uuidv4(),
          workplaceId: workplaceID,
          ...(values.quantity?.itemCount && { itemCount: parseInt(values.quantity.itemCount) }),
        });
        rootStore.addOrderExecution(orderExecution);

        const order = OrderModel.create({
          id: uuidv4(),
          number: getPrefixedNumber(values.order.number, values.order.numberPrefix),
        });
        orderExecution.update({ order });

        let product = null;

        if (selectedProduct) {
          product = selectedProduct;
        } else if (values.product?.name || values.product?.number) {
          product = ProductModel.create({
            id: uuidv4(),
            name: values.product.name,
            number: values.product.number
              ? getPrefixedNumber(values.product.number, values.product.numberPrefix)
              : null,
          });
        }
        rootStore.addOrder(order);

        if (product) {
          rootStore.addProduct(product);

          orderExecution.update({ product });
        }
        const query = startOrderExecutionRef.current(orderExecution);

        const { startOrderExecution } = (await query.currentPromise()) as {
          startOrderExecution: StartOrderExecutionPayloadModelType;
        };

        if (startOrderExecution.errors) {
          const nonFieldError = startOrderExecution.errors.find((error) => error.field === 'base');

          if (nonFieldError) {
            return null;
          }
          // TODO error handling
        } else {
          addStartedOrder(startOrderExecution.orderExecution?.order);

          clearSteps();

          setSelectedProduct(null);

          setSaving(false);

          setOrderExecutionModalOpened(false);
        }
      }
    },
  });

  useEffect(() => {
    debouncedValidateRef.current = debounce(validate, 200);
  }, [validate]);

  useEffect(() => {
    if (startOrderExecution) {
      startOrderExecutionRef.current = startOrderExecution;
    }
  }, [startOrderExecution]);

  useEffect(() => {
    setFormProps(formProps);
  }, [formProps, setFormProps]);

  useEffect(() => {
    let fields: string[] = [];

    orderExecutionConfig?.formOrderFields?.forEach((field) => {
      fields.push(`${OrderExecutionFormStep.ORDER}.${camelCase(field)}`);
    });
    orderExecutionConfig?.formProductFields?.forEach((field) => {
      fields.push(`${OrderExecutionFormStep.PRODUCT}.${camelCase(field)}`);
    });
    orderExecutionConfig?.formQuantityFields?.forEach((field) => {
      fields.push(`${OrderExecutionFormStep.QUANTITY}.${camelCase(field)}`);
    });
    setFieldsNames(fields.sort());
  }, [orderExecutionConfig, setFieldsNames]);

  useEffect(() => {
    setSteps([...new Set(fieldsNames.map((field) => field.split('.')[0] as OrderExecutionFormStep))]);
  }, [setSteps, fieldsNames]);

  return { formProps, saving };
};

export default useOrderExecutionForm;
