/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { QueryBuilder } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { RootStoreType } from "./index"


/**
 * OrderExecutionConfigBase
 * auto generated base class for the model OrderExecutionConfigModel.
 */
export const OrderExecutionConfigModelBase = ModelBase
  .named('OrderExecutionConfig')
  .props({
    __typename: types.optional(types.literal("OrderExecutionConfig"), "OrderExecutionConfig"),
    afterPauseReasonId: types.union(types.undefined, types.null, types.string),
    afterStartReasonId: types.union(types.undefined, types.null, types.string),
    afterStopReasonId: types.union(types.undefined, types.null, types.string),
    featureEnabled: types.union(types.undefined, types.boolean),
    formOrderFields: types.union(types.undefined, types.array(types.string)),
    formProductFields: types.union(types.undefined, types.array(types.string)),
    formQuantityFields: types.union(types.undefined, types.array(types.string)),
    itemCountUnit: types.union(types.undefined, types.string),
    orderInternalNoteTitle: types.union(types.undefined, types.null, types.string),
    orderInternalNoteText: types.union(types.undefined, types.null, types.string),
    productInternalNoteTitle: types.union(types.undefined, types.null, types.string),
    productInternalNoteText: types.union(types.undefined, types.null, types.string),
    quantityInternalNoteTitle: types.union(types.undefined, types.null, types.string),
    quantityInternalNoteText: types.union(types.undefined, types.null, types.string),
    noPlanNeedVerificationNote: types.union(types.undefined, types.null, types.string),
    noPlanCanCreateOrderNote: types.union(types.undefined, types.null, types.string),
    stopEnabled: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  }))

export class OrderExecutionConfigModelSelector extends QueryBuilder {
  get afterPauseReasonId() { return this.__attr(`afterPauseReasonId`) }
  get afterStartReasonId() { return this.__attr(`afterStartReasonId`) }
  get afterStopReasonId() { return this.__attr(`afterStopReasonId`) }
  get featureEnabled() { return this.__attr(`featureEnabled`) }
  get formOrderFields() { return this.__attr(`formOrderFields`) }
  get formProductFields() { return this.__attr(`formProductFields`) }
  get formQuantityFields() { return this.__attr(`formQuantityFields`) }
  get itemCountUnit() { return this.__attr(`itemCountUnit`) }
  get orderInternalNoteTitle() { return this.__attr(`orderInternalNoteTitle`) }
  get orderInternalNoteText() { return this.__attr(`orderInternalNoteText`) }
  get productInternalNoteTitle() { return this.__attr(`productInternalNoteTitle`) }
  get productInternalNoteText() { return this.__attr(`productInternalNoteText`) }
  get quantityInternalNoteTitle() { return this.__attr(`quantityInternalNoteTitle`) }
  get quantityInternalNoteText() { return this.__attr(`quantityInternalNoteText`) }
  get noPlanNeedVerificationNote() { return this.__attr(`noPlanNeedVerificationNote`) }
  get noPlanCanCreateOrderNote() { return this.__attr(`noPlanCanCreateOrderNote`) }
  get stopEnabled() { return this.__attr(`stopEnabled`) }
}
export function selectFromOrderExecutionConfig() {
  return new OrderExecutionConfigModelSelector()
}

export const orderExecutionConfigModelPrimitives = selectFromOrderExecutionConfig().afterPauseReasonId.afterStartReasonId.afterStopReasonId.featureEnabled.formOrderFields.formProductFields.formQuantityFields.itemCountUnit.orderInternalNoteTitle.orderInternalNoteText.productInternalNoteTitle.productInternalNoteText.quantityInternalNoteTitle.quantityInternalNoteText.noPlanNeedVerificationNote.noPlanCanCreateOrderNote.stopEnabled
