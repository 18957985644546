import styled, { css } from 'styled-components';

interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {
  display?: 'block' | 'flex';
  direction?: 'row' | 'column';
  align?: 'flex-start' | 'center' | 'flex-end';
}

export const Box = styled.div<BoxProps>`
  ${({ display, direction, align }) =>
    display === 'block'
      ? css`
          display: block;
        `
      : css`
          display: flex;
          flex-direction: ${direction || 'row'};
          align-items: ${align || 'center'};
        `};
`;
