/* eslint-disable promise/always-return, promise/catch-or-return */
import { UserData } from 'andoncloud-sdk';
import { Instance, types } from 'mobx-state-tree';
import moment from 'moment';
import { Query } from 'mst-gql';

import { getMutationErrorText, handleQueryError, isValidationError } from '@/helpers/errors';
import { statusChangesQS } from '@/queries';

import { ReasonModelType } from './ReasonModel';
import { StatusChangeModelBase } from './StatusChangeModel.base';
import { DatePrimitive } from './types';
import { UserModel } from './UserModel';

/* The TypeScript type of an instance of StatusChangeModel */
export interface StatusChangeModelType extends Instance<typeof StatusChangeModel.Type> {}

/* A graphql query fragment builders for StatusChangeModel */
export {
  selectFromStatusChange,
  statusChangeModelPrimitives,
  StatusChangeModelSelector,
} from './StatusChangeModel.base';

interface UpdateStatusChangeProps {
  userData: UserData;
  reason: ReasonModelType;
  reasonHasBeenRecommended: boolean;
  note: string | undefined;
  optimisticUpdate?: () => void;
  revertUpdate?: () => void;
  onSuccess?: () => void;
  onError?: (error: string, isValidationError?: boolean) => void;
}

/**
 * StatusChangeModel
 */
export const StatusChangeModel = StatusChangeModelBase.props({
  startedAt: types.union(types.undefined, DatePrimitive),
  finishedAt: types.union(types.undefined, types.null, DatePrimitive),
})
  .views((self) => ({
    durationMinutes(currentDate: Date) {
      if (self.finishedAt && self.startedAt) {
        return Math.abs(self.startedAt.diff(self.finishedAt, 'minutes'));
      }
      return Math.abs(moment(currentDate).diff(self.startedAt, 'minutes'));
    },
    durationSeconds(currentDate: Date) {
      if (self.finishedAt && self.startedAt) {
        return Math.abs(self.startedAt.diff(self.finishedAt, 'seconds') % 60);
      }
      return Math.abs(moment(currentDate).diff(self.startedAt, 'seconds') % 60);
    },
  }))
  .actions((self) => ({
    updateData({
      userData,
      reason,
      reasonHasBeenRecommended,
      note,
      optimisticUpdate,
      revertUpdate,
      onSuccess,
      onError,
    }: UpdateStatusChangeProps): Query {
      const author = UserModel.create({
        id: userData.id.toString(),
        login: userData.email,
        name: userData.name,
      });

      self.update({ author, reason, reasonHasBeenRecommended, note });

      const query = self.store.mutateUpdateStatusChange(
        {
          input: {
            id: self.id,
            authorId: userData.id.toString(),
            reasonId: reason.id,
            reasonHasBeenRecommended,
            note,
          },
        },
        statusChangesQS.UPDATE_STATUS_CHANGE_PAYLOAD_ALL,
        () => {
          if (optimisticUpdate) optimisticUpdate();
        },
      );
      const revert = () => {
        if (revertUpdate) revertUpdate();
      };

      query.then(
        ({ updateStatusChange }) => {
          const { errors } = updateStatusChange;

          if (errors?.length) {
            if (onError) onError(getMutationErrorText(errors[0]), isValidationError(errors[0]));
            revert();
            return;
          }
          if (onSuccess) onSuccess();
        },
        (error) => {
          handleQueryError(error, onError);
          revert();
        },
      );
      return query;
    },
  }));
