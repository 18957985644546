import styled from 'styled-components';

interface FormControlProps {
  rightGutter?: boolean;
}

export const Form = styled.form``;

export const FormControl = styled.div<FormControlProps>`
  margin-right: ${({ rightGutter }) => (rightGutter ? '8px' : '0')};
`;

export const FormGroup = styled.div``;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.dodgerBlue};
  > span {
    text-indent: 1em;
  }
`;

export const EmptyMessage = styled.div`
  margin-top: 4px;
`;
