import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { OrderExecutionModalView } from '@/components/const';
import { Button, DialogFooter, DialogFooterLink } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { getProductDisplayName } from '@/helpers/products';
import { OrderModelType, ProductionMode } from '@/models';
import { ReasonsParamTypes } from '@/types';

import { OrderExecutionModalProps } from '../..';

import {
  ContentWrapper,
  ItemContainer,
  ItemDetails,
  ItemDetailsLabel,
  ItemDetailsRow,
  ItemDetailsValue,
  ItemRadioButton,
  ItemsHeader,
  ItemsList,
  ItemsSeparator,
  PlannedExecutionContainer,
} from './styled';

const MAX_ORDERS_COUNT = 100;

const PlannedExecutionView: React.FC<OrderExecutionModalProps> = observer(({ state, companyConfig }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const [sortedStartedOrders, setSortedStartedOrders] = useState<OrderModelType[]>([]);
  const [sortedPlannedOrders, setSortedPlannedOrders] = useState<OrderModelType[]>([]);
  const intl = useIntl();

  const workplaceConfig = getWorkplaceConfig(companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const orderExecutionConfig = workplaceConfig?.orderExecutionConfig;
  const {
    startedOrders,
    plannedOrders,
    setCurrentView,
    selectedOrder,
    setSelectedOrder,
    setOrderExecutionModalOpened,
  } = state;
  const isPlannedMode = workplaceConfig?.productionMode === ProductionMode.PLANNED;
  const isSelected = (order: OrderModelType) => selectedOrder?.id === order.id;

  useEffect(() => {
    if (startedOrders?.length) {
      setSortedStartedOrders(
        startedOrders
          .sort((o1, o2) => {
            if (!o1.startedAt) return 1;
            if (!o2.startedAt) return -1;

            return Number(new Date(o2.startedAt)) - Number(new Date(o1.startedAt));
          })
          .slice(0, MAX_ORDERS_COUNT),
      );
    }
  }, [startedOrders]);

  useEffect(() => {
    if (plannedOrders?.length) {
      setSortedPlannedOrders(
        plannedOrders
          .sort((o1, o2) => {
            if (o1.position === null) return 1;
            if (o2.position === null) return -1;

            return (o1.position || 0) - (o2.position || 0);
          })
          .slice(0, MAX_ORDERS_COUNT),
      );
    }
  }, [plannedOrders]);

  useEffect(() => {
    if (sortedStartedOrders.length) {
      setSelectedOrder(sortedStartedOrders[0]);
    } else if (sortedPlannedOrders.length) {
      setSelectedOrder(sortedPlannedOrders[0]);
    }
  }, [sortedStartedOrders, sortedPlannedOrders, setSelectedOrder]);

  const handleCancel = () => {
    setSelectedOrder(null);

    setOrderExecutionModalOpened(false);
  };

  const handleOrderChange = (order: OrderModelType) => {
    setSelectedOrder(order);
  };

  const handleStart = () => {
    setCurrentView(OrderExecutionModalView.PLANNED_SUMMARY);
  };

  const renderOrderItem = (order: OrderModelType) => {
    const item = order.items[0];

    return (
      <ItemContainer key={order.id} active={isSelected(order)} onClick={() => handleOrderChange(order)}>
        <ItemDetails active={isSelected(order)}>
          <ItemDetailsRow active={isSelected(order)}>
            <ItemDetailsLabel>
              <FormattedMessage defaultMessage="Order" description="Planned mode view order label" />:
            </ItemDetailsLabel>
            <ItemDetailsValue bold>{order.number}</ItemDetailsValue>
          </ItemDetailsRow>
          {item && (
            <>
              <ItemDetailsRow>
                <ItemDetailsLabel>
                  <FormattedMessage defaultMessage="Product" description="Planned mode view item product label" />:
                </ItemDetailsLabel>
                <ItemDetailsValue>{getProductDisplayName(item.product)}</ItemDetailsValue>
              </ItemDetailsRow>
              <ItemDetailsRow>
                <ItemDetailsLabel>
                  <FormattedMessage defaultMessage="Quantity" description="Planned mode view item quantity label" />:
                </ItemDetailsLabel>
                <ItemDetailsValue>
                  {order.executedCount} / {item.count} {orderExecutionConfig?.itemCountUnit}
                </ItemDetailsValue>
              </ItemDetailsRow>
            </>
          )}
        </ItemDetails>
        <ItemRadioButton type="radio" checked={isSelected(order)} onChange={() => handleOrderChange(order)} />
      </ItemContainer>
    );
  };

  const renderStartedOrders = () => {
    return (
      <>
        <ItemsHeader>
          {!!orderConfig?.alternativeName
            ? intl.formatMessage({
                defaultMessage: 'Started orders',
                description: 'Planned execution view started orders alternative header',
              })
            : intl.formatMessage({
                defaultMessage: 'Started orders',
                description: 'Planned execution view started orders header',
              })}
        </ItemsHeader>
        <ItemsList data-testid="started-orders-list">
          {sortedStartedOrders.map((order) => renderOrderItem(order))}
        </ItemsList>
      </>
    );
  };

  const renderPlanedOrders = () => {
    return (
      <>
        {!!sortedStartedOrders.length && <ItemsSeparator />}
        <ItemsHeader>
          <FormattedMessage
            defaultMessage="Next to be executed"
            description="Planned execution view planned orders header"
          />
        </ItemsHeader>
        <ItemsList data-testid="planned-orders-list">
          {sortedPlannedOrders.map((order) => renderOrderItem(order))}
        </ItemsList>
      </>
    );
  };

  return (
    <PlannedExecutionContainer data-testid="planned-execution-view">
      <ContentWrapper>
        {!!sortedStartedOrders.length && renderStartedOrders()}
        {isPlannedMode && !!sortedPlannedOrders.length && renderPlanedOrders()}
      </ContentWrapper>
      <DialogFooter>
        <Row alignItems="center" justifyContent="between">
          <Col col="auto" noGutter>
            <Button
              type="button"
              size="large"
              onClick={handleCancel}
              data-testid="planned-execution-view-cancel-button"
            >
              <FormattedMessage defaultMessage="Cancel" description="Planned execution view cancel button" />
            </Button>
          </Col>
          <Col col="auto" noGutter>
            <Row alignItems="center" noGutter>
              {companyConfig?.roleConfig?.orderCreationAllowed && (
                <Col col="auto" noGutter>
                  <DialogFooterLink
                    onClick={() => setCurrentView(OrderExecutionModalView.MANUAL)}
                    data-testid="planned-execution-view-create-order-link"
                  >
                    {!!orderConfig?.alternativeName ? (
                      <FormattedMessage
                        defaultMessage="Add order"
                        description="Planned execution view add order button alternative"
                      />
                    ) : (
                      <FormattedMessage
                        defaultMessage="Add order"
                        description="Planned execution view add order button"
                      />
                    )}
                  </DialogFooterLink>
                </Col>
              )}
              <Col col="auto" noGutter>
                <Button
                  type="button"
                  size="large"
                  onClick={() => handleStart()}
                  data-testid="planned-execution-view-start-button"
                  primary
                >
                  {!!orderConfig?.alternativeName ? (
                    <FormattedMessage
                      defaultMessage="Select order"
                      description="Planned execution view submit button alternative"
                    />
                  ) : (
                    <FormattedMessage
                      defaultMessage="Select order"
                      description="Planned execution view submit button"
                    />
                  )}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </DialogFooter>
    </PlannedExecutionContainer>
  );
});

export default PlannedExecutionView;
