import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { OrderExecutionModalView } from '@/components/const';
import { Dialog } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { CompanyConfigModelType, ProductionMode, useQuery } from '@/models';
import { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

import AlreadyPlannedAlertModal from './alerts/AlreadyPlannedAlertModal';
import ExecutionInProgressAlertModal from './alerts/ExecutionInProgressAlertModal';
import UnsavedChangesAlertModal from './alerts/UnsavedChangesAlertModal';
import ManualExecutionView from './views/ManualExecutionView';
import NoPermissionsView from './views/NoPermissionsView';
import NoPlanView from './views/NoPlanView';
import PlannedExecutionSummaryView from './views/PlannedExecutionSummaryView';
import PlannedExecutionView from './views/PlannedExecutionView';

export interface OrderExecutionModalProps {
  state: OrderExecutionModalState;
  companyConfig: CompanyConfigModelType;
}

const OrderExecutionModal: React.FC<OrderExecutionModalProps> = observer(({ state, companyConfig }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { data: startedOrdersData, loading: startedOrdersLoading } = useQuery((store) =>
    store.queryOrders({
      filter: {
        started_at_not_null: 'true',
        finished_at_null: 'true',
        workplaces_id_equals: workplaceID,
      },
    }),
  );
  const { data: plannedOrdersData, loading: plannedOrdersLoading } = useQuery((store) =>
    store.queryOrders({
      filter: {
        started_at_null: 'true',
        finished_at_null: 'true',
        position_not_null: 'true',
        workplaces_id_equals: workplaceID,
      },
    }),
  );
  const workplaceConfig = getWorkplaceConfig(companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const intl = useIntl();

  const {
    currentView,
    setCurrentView,
    startedOrders,
    setStartedOrders,
    plannedOrders,
    setPlannedOrders,
    orderExecutionModalOpened,
    orderExecutionModalHidden,
  } = state;

  useEffect(() => {
    if (
      (!currentView || !orderExecutionModalOpened) &&
      !startedOrdersLoading &&
      startedOrders !== null &&
      !plannedOrdersLoading &&
      plannedOrders !== null
    ) {
      const isManualMode = workplaceConfig?.productionMode === ProductionMode.MANUAL;
      const isPlannedMode = workplaceConfig?.productionMode === ProductionMode.PLANNED;

      if ((isManualMode && startedOrders.length) || (isPlannedMode && (startedOrders.length || plannedOrders.length))) {
        setCurrentView(OrderExecutionModalView.PLANNED);
      } else if (isPlannedMode) {
        setCurrentView(OrderExecutionModalView.NO_PLAN);
      } else if (!companyConfig?.roleConfig?.orderCreationAllowed) {
        setCurrentView(OrderExecutionModalView.NO_PERMISSIONS);
      } else {
        setCurrentView(OrderExecutionModalView.MANUAL);
      }
    }
  }, [
    companyConfig?.roleConfig?.orderCreationAllowed,
    orderExecutionModalOpened,
    startedOrders,
    startedOrdersLoading,
    plannedOrders,
    plannedOrdersLoading,
    currentView,
    setCurrentView,
    workplaceConfig,
  ]);

  useEffect(() => {
    if (!startedOrdersLoading && startedOrdersData) {
      setStartedOrders(startedOrdersData.orders);
    }
  }, [startedOrdersLoading, startedOrdersData, setStartedOrders]);

  useEffect(() => {
    if (!plannedOrdersLoading && plannedOrdersData) {
      setPlannedOrders(plannedOrdersData.orders);
    }
  }, [plannedOrdersLoading, plannedOrdersData, setPlannedOrders]);

  const getTitle = () => {
    switch (currentView) {
      case OrderExecutionModalView.PLANNED:
        return !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Orders scheduled for execution',
              description: 'Order execution modal planned view alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'Orders scheduled for execution',
              description: 'Order execution modal planned view title',
            });
      case OrderExecutionModalView.PLANNED_SUMMARY:
        return !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Process the order',
              description: 'Order execution modal planned summary view alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'Process the order',
              description: 'Order execution modal planned summary view title',
            });
      case OrderExecutionModalView.MANUAL:
        return !!orderConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Process the order',
              description: 'Order execution modal manual view alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'Process the order',
              description: 'Order execution modal manual view title',
            });
      default:
        return null;
    }
  };

  const getDialogMinHeight = () => {
    if (!currentView) return;

    return [OrderExecutionModalView.NO_PERMISSIONS, OrderExecutionModalView.NO_PLAN].includes(currentView)
      ? undefined
      : 800;
  };

  const renderCurrentView = () => {
    switch (currentView) {
      case OrderExecutionModalView.PLANNED:
        return <PlannedExecutionView state={state} companyConfig={companyConfig} />;
      case OrderExecutionModalView.PLANNED_SUMMARY:
        return <PlannedExecutionSummaryView state={state} companyConfig={companyConfig} />;
      case OrderExecutionModalView.MANUAL:
        return <ManualExecutionView state={state} companyConfig={companyConfig} />;
      case OrderExecutionModalView.NO_PERMISSIONS:
        return <NoPermissionsView state={state} companyConfig={companyConfig} />;
      case OrderExecutionModalView.NO_PLAN:
        return <NoPlanView state={state} companyConfig={companyConfig} />;
      default:
        return null;
    }
  };

  return (
    !!orderConfig && (
      <>
        <Dialog
          title={getTitle()}
          open={orderExecutionModalOpened}
          hidden={orderExecutionModalHidden}
          minHeight={getDialogMinHeight()}
          contentPadding="32px 32px 0 32px"
          data-testid="order-execution-modal"
        >
          {renderCurrentView()}
        </Dialog>
        <ExecutionInProgressAlertModal state={state} />
        <AlreadyPlannedAlertModal state={state} />
        <UnsavedChangesAlertModal state={state} />
      </>
    )
  );
});

export default OrderExecutionModal;
