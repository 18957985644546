import { Instance } from 'mobx-state-tree';

import { ProductConfigModelBase } from './ProductConfigModel.base';

/* The TypeScript type of an instance of ProductConfigModel */
export interface ProductConfigModelType extends Instance<typeof ProductConfigModel.Type> {}

/* A graphql query fragment builders for ProductConfigModel */
export {
  selectFromProductConfig,
  productConfigModelPrimitives,
  ProductConfigModelSelector,
} from './ProductConfigModel.base';

/**
 * ProductConfigModel
 */
export const ProductConfigModel = ProductConfigModelBase;
