import { useIntl } from 'react-intl';

import { FormikProps } from 'formik';

import { DialogStep, FieldError, TextField } from '@/components/core';
import { OrderExecutionConfigModelType, ProductConfigModelType } from '@/models';
import { OrderExecutionFormValues, OrderExecutionModalState } from '@/types';

import { FormControl } from '../../styled';
import useHandlers from '../hooks/useHandlers';

interface OrderStepProps {
  active?: boolean;
  completed?: boolean;
  formProps: FormikProps<OrderExecutionFormValues>;
  orderExecutionConfig: OrderExecutionConfigModelType;
  orderExecutionModalState: OrderExecutionModalState;
  productConfig: ProductConfigModelType;
  handlers: ReturnType<typeof useHandlers>;
}

const QuantityStep: React.FC<OrderStepProps> = ({
  active,
  completed,
  formProps,
  orderExecutionConfig,
  orderExecutionModalState,
  productConfig,
  handlers,
}) => {
  const {
    steps,
    activeStep,
    setActiveStep,
    completedSteps,
    setCompletedSteps,
    removeCompletedStep,
    getFieldsInCurrentStep,
    validateCurrentStep,
    setCurrentField,
    isCurrentField,
    setLastActiveField,
  } = orderExecutionModalState;
  const { handleKeyPress } = handlers;
  const intl = useIntl();

  const getSummaryRecords = () => {
    let records = [];

    if (formProps.values.quantity?.itemCount) {
      records.push({
        value: formProps.values.quantity.itemCount,
        unit: orderExecutionConfig?.itemCountUnit,
      });
    }
    return records;
  };

  return (
    <DialogStep
      active={active}
      completed={completed}
      step={3}
      setActiveStep={async (stepNumber) => {
        const step = steps[stepNumber - 1];

        if (activeStep && (await validateCurrentStep())) {
          setCompletedSteps([...completedSteps, activeStep]);
        }
        removeCompletedStep(step);

        setActiveStep(step);

        setCurrentField(`${step}.${getFieldsInCurrentStep()[0]}`);
      }}
      title={
        !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Planned number of products',
              description: 'Quantity step alternative title',
            })
          : intl.formatMessage({
              defaultMessage: 'Planned number of products',
              description: 'Quantity step title',
            })
      }
      subtitle={
        !!productConfig?.alternativeName
          ? intl.formatMessage({
              defaultMessage: 'Enter the planned number of products to be produced under the order.',
              description: 'Quantity step alternative subtitle',
            })
          : intl.formatMessage({
              defaultMessage: 'Enter the planned number of products to be produced under the order.',
              description: 'Quantity step subtitle',
            })
      }
      noteTitle={orderExecutionConfig?.quantityInternalNoteTitle}
      noteText={orderExecutionConfig?.quantityInternalNoteText}
      summaryRecords={getSummaryRecords()}
      data-testid="order-execution-form-quantity-step"
    >
      <FormControl>
        <TextField
          name="quantity.itemCount"
          label={
            !!productConfig?.alternativeName
              ? intl.formatMessage({
                  defaultMessage: 'Quantity of products',
                  description: 'Order execution form quantity item count field alternative label',
                })
              : intl.formatMessage({
                  defaultMessage: 'Quantity of products',
                  description: 'Order execution form quantity item count field label',
                })
          }
          unit={orderExecutionConfig?.itemCountUnit}
          data-testid="order-execution-quantity-item-count-field"
          placeholder={
            !!productConfig?.alternativeName
              ? intl.formatMessage({
                  defaultMessage: 'Enter the quantity',
                  description: 'Order execution form quantity item count field alternative placeholder',
                })
              : intl.formatMessage({
                  defaultMessage: 'Enter the quantity',
                  description: 'Order execution form quantity item count field placeholder',
                })
          }
          value={formProps.values.quantity.itemCount}
          onChange={formProps.handleChange}
          onKeyDown={handleKeyPress}
          onBlur={() => {
            setCurrentField(null);

            setLastActiveField('quantity.itemCount');
          }}
          onFocus={() => setCurrentField('quantity.itemCount')}
          focus={isCurrentField('quantity.itemCount')}
          error={formProps.touched.quantity?.itemCount && Boolean(formProps.errors.quantity?.itemCount)}
          width="300px"
        />
        <FieldError
          message={formProps.touched.quantity?.itemCount && formProps.errors.quantity?.itemCount}
          data-testid="order-execution-quantity-item-count-field-error"
        />
      </FormControl>
    </DialogStep>
  );
};

export default QuantityStep;
