import { Col, Container } from 'styled-bootstrap-grid';
import styled from 'styled-components';

import editIconGray from '@/images/editGray.svg';
import editIconWhite from '@/images/editWhite.svg';

interface DialogStepIndicatorProps {
  active?: boolean;
  completed?: boolean;
}

interface DialogStepTitleProps {
  active?: boolean;
  completed?: boolean;
}

export const DialogStep = styled(Container)`
  min-width: 740px;
  padding: 0;
  &.dialog-step:nth-last-child(1) .dialog-step-content {
    margin-bottom: 0;
    border-bottom: none;
  }
  @media (max-height: 800px) {
    padding-right: 24px;
  }
`;

export const DialogStepIndicator = styled.div<DialogStepIndicatorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-weight: 400;
  color: ${({ active, theme }) => (active ? theme.colors.pixelWhite : theme.colors.codexGray)};
  border-radius: 50%;
  background-color: ${({ theme, completed }) => (completed ? theme.colors.pesteringPesto : theme.colors.thunder)};
`;

export const DialogStepIndicatorIcon = styled.img`
  display: block;
  width: 20px;
  height: 20px;
`;

export const DialogStepTitle = styled.h5<DialogStepTitleProps>`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ active, completed, theme }) => (active || completed ? theme.colors.pixelWhite : theme.colors.codexGray)};
`;

export const DialogStepSubtitle = styled.h6`
  margin: 8px 0 0 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.codexGray};
`;

export const DialogStepActions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DialogStepActionLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.codexGray};
  &:before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    background-image: url(${editIconGray});
  }
  &:hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    &:before {
      background-image: url(${editIconWhite});
    }
  }
`;

export const DialogStepNote = styled(Container)`
  margin-top: 24px;
  padding: 8px;
  border: 2px solid ${({ theme }) => theme.colors.redOrangeJuice};
  border-radius: 5px;
`;

export const DialogStepNoteIcon = styled.img``;

export const DialogStepNoteTitle = styled.h6`
  margin: 0;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.redOrangeJuice};
`;

export const DialogStepNoteText = styled.p`
  margin: 4px 0 0 0;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.wolfram};
`;

export const DialogStepContent = styled(Col)`
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.thunder};
`;

export const DialogStepSummary = styled(Container)`
  margin-top: 24px;
  padding: 0;
`;

export const DialogStepSummaryRecord = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DialogStepSummaryRecordLabel = styled.span`
  margin-bottom: 8px;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.wolfram};
`;

export const DialogStepSummaryRecordValue = styled.span``;

export const DialogStepSummaryRecordUnit = styled.span`
  margin-left: 0.25em;
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.wolfram};
  white-space: nowrap;
  &:before {
    content: ' / ';
  }
`;
