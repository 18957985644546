import { createStatusChangePayloadModelPrimitives } from '@/models/CreateStatusChangePayloadModel.base';
import { mutationErrorModelPrimitives } from '@/models/MutationErrorModel.base';
import { orderModelPrimitives } from '@/models/OrderModel.base';
import { productModelPrimitives } from '@/models/ProductModel.base';
import { statusChangeModelPrimitives } from '@/models/StatusChangeModel.base';
import { updateStatusChangePayloadModelPrimitives } from '@/models/UpdateStatusChangePayloadModel.base';

export default {
  ALL: statusChangeModelPrimitives
    .author((author) => author.id.login.name)
    .reason((reason) => reason.id.acceptanceMonit.hasNote.leaf.name.parentId.position.statusColor)
    .order(orderModelPrimitives)
    .product(productModelPrimitives)
    .toString(),
  CREATE_STATUS_CHANGE_PAYLOAD_ALL: createStatusChangePayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .statusChange(statusChangeModelPrimitives)
    .toString(),
  UPDATE_STATUS_CHANGE_PAYLOAD_ALL: updateStatusChangePayloadModelPrimitives
    .errors(mutationErrorModelPrimitives)
    .statusChange(statusChangeModelPrimitives)
    .toString(),
};
