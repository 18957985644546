import { FormattedMessage } from 'react-intl';

import { Col, Row } from 'styled-bootstrap-grid';

import checkIcon from '@/images/check.svg';

import DialogStepNote from './Note';
import {
  DialogStep as StyledDialogStep,
  DialogStepActionLink,
  DialogStepActions,
  DialogStepContent,
  DialogStepIndicator,
  DialogStepIndicatorIcon,
  DialogStepSubtitle,
  DialogStepTitle,
} from './styled';
import { DialogStepSummary, DialogStepSummaryRecordType } from './Summary';

interface DialogStepProps {
  children?: React.ReactNode;
  active?: boolean;
  completed?: boolean;
  editable?: boolean;
  step: number;
  setActiveStep?: (step: number) => void;
  title: string;
  subtitle?: string;
  noteTitle?: string | null;
  noteText?: string | null;
  summaryRecords?: DialogStepSummaryRecordType[];
  'data-testid'?: string;
}

export const DialogStep: React.FC<DialogStepProps> = ({
  children,
  active,
  completed,
  editable = true,
  step,
  setActiveStep,
  title,
  subtitle,
  noteTitle,
  noteText,
  summaryRecords = [],
  'data-testid': dataTestID,
}) => {
  const renderContent = () => {
    if (active) return children;

    return completed ? <DialogStepSummary records={summaryRecords} data-testid={`${dataTestID}-summary`} /> : null;
  };

  return (
    <StyledDialogStep data-testid={dataTestID} className="dialog-step">
      <Row>
        <Col col={0.6} noGutter>
          <DialogStepIndicator active={active} completed={completed}>
            {completed ? <DialogStepIndicatorIcon src={checkIcon} /> : step}
          </DialogStepIndicator>
        </Col>
        <Col col noGutter>
          <DialogStepTitle active={active} completed={completed}>
            {title}
          </DialogStepTitle>
        </Col>
        {completed && editable && (
          <Col col>
            <DialogStepActions>
              <DialogStepActionLink
                onClick={() => {
                  if (setActiveStep) {
                    setActiveStep(step);
                  }
                }}
              >
                <FormattedMessage defaultMessage="Edit" description="Dialog step edit action text" />
              </DialogStepActionLink>
            </DialogStepActions>
          </Col>
        )}
      </Row>
      {active && subtitle && (
        <Row>
          <Col col offset={0.6} noGutter>
            <DialogStepSubtitle>{subtitle}</DialogStepSubtitle>
          </Col>
        </Row>
      )}
      {active && noteTitle && noteText && (
        <Row>
          <Col col offset={0.6} noGutter>
            <DialogStepNote title={noteTitle} text={noteText} />
          </Col>
        </Row>
      )}
      <Row>
        <DialogStepContent col offset={0.6} noGutter className="dialog-step-content">
          {renderContent()}
        </DialogStepContent>
      </Row>
    </StyledDialogStep>
  );
};
