import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { OrderExecutionFormStep, OrderExecutionModalView } from '@/components/const';
import { Button, DialogFooter } from '@/components/core';
import { OrderConfigModelType, OrderExecutionConfigModelType, ProductConfigModelType } from '@/models';
import type { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

import useHandlers from './hooks/useHandlers';
import useOrderExecutionForm from './hooks/useOrderExecutionForm';
import OrderStep from './steps/OrderStep';
import ProductStep from './steps/ProductStep';
import QuantityStep from './steps/QuantityStep';
import { OrderExecutionForm as StyledOrderExecutionForm, OrderExecutionFormSteps } from './styled';

interface OrderExecutionFormProps {
  children?: React.ReactNode;
  orderExecutionModalState: OrderExecutionModalState;
  orderConfig: OrderConfigModelType;
  orderExecutionConfig: OrderExecutionConfigModelType;
  productConfig: ProductConfigModelType;
}

const OrderExecutionForm: React.FC<OrderExecutionFormProps> = observer(
  ({ children, orderExecutionModalState, orderConfig, orderExecutionConfig, productConfig }) => {
    const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;

    const {
      previousView,
      activeStep,
      completedSteps,
      setActiveStep,
      allStepsCompleted,
      fieldsNames,
      setCurrentField,
      validating,
    } = orderExecutionModalState;

    const { formProps, saving } = useOrderExecutionForm(
      workplaceID,
      orderExecutionModalState,
      orderConfig,
      orderExecutionConfig,
      productConfig,
    );

    const handlers = useHandlers({ workplaceId: workplaceID, orderExecutionModalState, formProps });

    const { handleNextButtonClick, handleCancel, handleReset, handleSubmit } = handlers;

    useEffect(() => {
      const firstStep = fieldsNames[0]?.split('.')[0] as OrderExecutionFormStep;

      if (firstStep) {
        setActiveStep(firstStep);
      }
    }, [fieldsNames, setActiveStep]);

    useEffect(() => {
      setCurrentField(fieldsNames[0]);
    }, [fieldsNames, setCurrentField]);

    const shouldRenderStep = (step: OrderExecutionFormStep) => {
      return fieldsNames.some((fieldName) => fieldName.split('.')[0] === step);
    };

    const renderButtons = () => (
      <Row justifyContent="between">
        <Col col="auto" noGutter>
          <Button type="button" size="large" onClick={handleCancel} data-testid="order-execution-form-cancel-button">
            {previousView === OrderExecutionModalView.PLANNED ? (
              <FormattedMessage defaultMessage="Back" description="Order execution form back button" />
            ) : (
              <FormattedMessage defaultMessage="Cancel" description="Order execution form cancel button" />
            )}
          </Button>
        </Col>
        <Col col="auto" noGutter>
          {allStepsCompleted() ? (
            <Button
              type="submit"
              size="large"
              data-testid="order-execution-form-submit-button"
              focus={allStepsCompleted() && !Object.keys(formProps.errors).length}
              disabled={!!Object.keys(formProps.errors).length || saving}
              primary
            >
              <FormattedMessage defaultMessage="Start" description="Order execution form submit button" />
            </Button>
          ) : (
            <Button
              type="button"
              size="large"
              onClick={handleNextButtonClick}
              data-testid="order-execution-form-next-button"
              disabled={validating}
              primary
            >
              <FormattedMessage defaultMessage="Next" description="Order execution form next button" />
            </Button>
          )}
        </Col>
      </Row>
    );

    return (
      <StyledOrderExecutionForm
        data-testid="order-execution-form"
        onReset={handleReset}
        onSubmit={handleSubmit}
        autoComplete="off"
        noValidate
      >
        <OrderExecutionFormSteps>
          <OrderStep
            active={activeStep === OrderExecutionFormStep.ORDER}
            completed={completedSteps.includes(OrderExecutionFormStep.ORDER)}
            formProps={formProps}
            orderExecutionModalState={orderExecutionModalState}
            orderConfig={orderConfig}
            orderExecutionConfig={orderExecutionConfig}
            handlers={handlers}
          />
          {shouldRenderStep(OrderExecutionFormStep.PRODUCT) && (
            <ProductStep
              active={activeStep === OrderExecutionFormStep.PRODUCT}
              completed={completedSteps.includes(OrderExecutionFormStep.PRODUCT)}
              formProps={formProps}
              orderExecutionModalState={orderExecutionModalState}
              orderExecutionConfig={orderExecutionConfig}
              productConfig={productConfig}
              handlers={handlers}
            />
          )}
          {shouldRenderStep(OrderExecutionFormStep.QUANTITY) && (
            <QuantityStep
              active={activeStep === OrderExecutionFormStep.QUANTITY}
              completed={completedSteps.includes(OrderExecutionFormStep.QUANTITY)}
              formProps={formProps}
              orderExecutionConfig={orderExecutionConfig}
              orderExecutionModalState={orderExecutionModalState}
              productConfig={productConfig}
              handlers={handlers}
            />
          )}
          {children}
        </OrderExecutionFormSteps>
        <DialogFooter>{renderButtons()}</DialogFooter>
      </StyledOrderExecutionForm>
    );
  },
);

export default OrderExecutionForm;
