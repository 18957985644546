import { Col, Row } from 'styled-bootstrap-grid';
import styled, { css } from 'styled-components';

interface ItemLabelProps {
  invalid?: boolean;
}

export const ItemRow = styled(Row)`
  align-items: center;
  padding: 0 10px 0 5px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  &.auto-height {
    max-height: none !important;
    height: auto !important;
    padding: 10px 10px 10px 5px !important;
  }
  &:nth-child(2n-1) {
    background-color: ${({ theme }) => theme.colors.youtubeBlack};
  }
`;

export const ItemLabel = styled(Col)<ItemLabelProps>`
  text-indent: 0.5em;
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.persimmon};
      text-decoration: line-through;
    `}
`;

export const ItemValue = styled(Col)<ItemLabelProps>`
  ${({ invalid }) =>
    invalid &&
    css`
      color: ${({ theme }) => theme.colors.persimmon};
    `}
`;

export const ListContainer = styled.div`
  overflow-y: auto;
  height: 100%;
`;
