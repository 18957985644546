import styled, { css } from 'styled-components';

interface ItemProps {
  isHighlighted?: boolean;
  isSelected?: boolean;
}

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: auto;
  width: 100%;
  max-height: 50vh;
  margin-top: 8px;
  padding: 16px 12px;
  font-size: 12px;
  border: ${({ theme }) => `1px solid ${theme.colors.thunder}`};
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.offBlack};
  box-shadow: 0px 4px 11.2px 0px rgba(0, 0, 0, 0.25);
  z-index: 1000;
`;

export const MenuTitle = styled.span`
  margin-bottom: 6px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.35);
`;

export const Item = styled.div<ItemProps>`
  width: 100%;
  padding: 4px;
  cursor: pointer;
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  > span {
    border-radius: 2px;
    background: rgba(21, 94, 209, 0.25);
  }
  &:hover {
    border-radius: 2px;
    background-color: rgba(21, 94, 209, 0.25);
  }
  ${(props) =>
    props.isSelected &&
    css`
      border-radius: 2px;
      background-color: rgba(21, 94, 209, 0.25);
    `}
`;
