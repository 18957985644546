import styled from 'styled-components';

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 9vh repeat(4, 10vh) 6vh;
  grid-template-areas:
    'input     input     input     input'
    'num-1     num-2     num-3     addOperation'
    'num-4     num-5     num-6     addOperation'
    'num-7     num-8     num-9     subtractOperation'
    'num-0     num-0     backspace subtractOperation'
    'reject    reject    confirm   confirm';
  @media (max-height: 1000px) {
    grid-gap: 10px;
  }
`;

export const Message = styled.p`
  margin: 2.5vh 15px 0.5vh 15px;
  width: 100%;
  text-align: center;
  font-size: 17px;
  line-height: 22px;
  color: rgba(235, 235, 245, 0.6);
`;

export const ValueDisplay = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  background: rgba(79, 79, 79, 0.2);
`;

export const ValueDisplayText = styled.span`
  font-size: 24px;
  line-height: 22px;
  color: rgba(235, 235, 245, 0.6);
`;
export const ValueDisplayDescription = styled.span`
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
  line-height: 22px;
  color: rgba(235, 235, 245, 0.4);
`;
export const HeaderContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 10px 10px 10px;
  gap: 10px;
`;
