import { Instance } from 'mobx-state-tree';

import { OrderConfigModelBase } from './OrderConfigModel.base';

/* The TypeScript type of an instance of OrderConfigModel */
export interface OrderConfigModelType extends Instance<typeof OrderConfigModel.Type> {}

/* A graphql query fragment builders for OrderConfigModel */
export { selectFromOrderConfig, orderConfigModelPrimitives, OrderConfigModelSelector } from './OrderConfigModel.base';

/**
 * OrderConfigModel
 */
export const OrderConfigModel = OrderConfigModelBase;
