import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import { Container } from 'andoncloud-sdk';
import Cookies from 'js-cookie';
import { observer } from 'mobx-react-lite';
import { Col, Row } from 'styled-bootstrap-grid';

import { CounterOperation } from '@/components/const';
import CounterModal from '@/components/CounterModal';
import Counters from '@/components/Counters';
import CountersKeyboard from '@/components/CountersKeyboard';
import { History } from '@/components/History';
import Notification from '@/components/Notification';
import { Reasons } from '@/components/Reasons';
import StandardRateModal from '@/components/StandardRateModal';
import WorkplaceFooter from '@/components/WorkplaceFooter';
import { getSidebarMenuItems, getWorkplaceConfig } from '@/helpers';
import { hexToRgba } from '@/helpers/colors';
import { notifyServerError } from '@/helpers/errors';
import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { useLastOrderExecution, useLastStatusChange, useStore } from '@/hooks';
import { useCurrentWorkplace } from '@/hooks/useCurrentWorkplace';
import { AndonLightColor, CounterModelType, NotificationModelType, ScreenEnum, useQuery } from '@/models';
import {
  StatusChangeTransitionPermissionsProvider,
  useCompanyData,
  useLastWorkplace,
  useLocale,
  useWorkplaceData,
} from '@/providers';
import { CounterModalCallback } from '@/types';

interface WorkplacePageProps {
  provideAllPermissions?: boolean;
}

const WorkplacePage: React.FC<WorkplacePageProps> = observer(({ provideAllPermissions }) => {
  const { notifications } = useStore();
  const { currentWorkplace, workplaceID } = useCurrentWorkplace();
  const { setLastWorkplaceId } = useLastWorkplace();
  const lastOrderExecution = useLastOrderExecution();
  const lastStatusChange = useLastStatusChange();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const { data: workplaceData, loading: workplaceDataLoading } = useWorkplaceData();
  const { setQuery: setMutationQuery } = useQuery();
  const [currentScreen, setCurrentScreen] = useState<string>(ScreenEnum.STATUS_SCREEN);
  const [historyFeatureVisibility, setHistoryFeatureVisibility] = useState<boolean>();
  const [counterModalCallback, setCounterModalCallback] = useState<CounterModalCallback | null>(null);
  const [selectedCounter, setSelectedCounter] = useState<CounterModelType | undefined>();
  const [operationType, setOperationType] = useState<CounterOperation | undefined>();
  const [workplaceNotifications, setWorkplaceNotifications] = useState<NotificationModelType[]>([]);
  const [headerColor, setHeaderColor] = useState<string>();
  const { toggleLocale } = useLocale();
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useLocation();
  const currentWorkplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);

  // this useEffect sets cookies related to screen saver
  useEffect(() => {
    Cookies.remove('screen-saver_idle-time');
    Cookies.remove('screen-saver_enabled');
    Cookies.remove('screen-saver_display-after-idle');
    if (
      currentWorkplaceConfig?.screenSaverConfig?.enabled !== undefined &&
      currentWorkplaceConfig?.screenSaverConfig.idleTime &&
      currentWorkplaceConfig?.screenSaverConfig.displayAfterIdle !== undefined &&
      currentWorkplaceConfig.screenSaverConfig.autoSignOut !== undefined
    ) {
      // if all values are defined we set cookies based on the latest fetched screen saver config
      Cookies.set('screen-saver_enabled', currentWorkplaceConfig.screenSaverConfig.enabled.toString());
      Cookies.set('screen-saver_idle-time', currentWorkplaceConfig.screenSaverConfig.idleTime.toString());
      Cookies.set(
        'screen-saver_display-after-idle',
        currentWorkplaceConfig.screenSaverConfig.displayAfterIdle.toString(),
      );
      Cookies.set('screen-saver_auto-sign-out', currentWorkplaceConfig.screenSaverConfig.autoSignOut.toString());
    }
  }, [currentWorkplaceConfig]);

  useEffect(() => {
    if (params.pathname.includes('/counters')) {
      setCurrentScreen(ScreenEnum.COUNTERS);
    } else if (params.pathname.includes('/reasons')) {
      setCurrentScreen(ScreenEnum.STATUSES);
    } else if (params.pathname.includes('/status-screen')) {
      setCurrentScreen(ScreenEnum.STATUS_SCREEN);
    }
  }, [params.pathname]);

  useEffect(() => {
    if (!companyDataLoading) {
      setLastWorkplaceId(workplaceID);
    }
  }, [companyDataLoading, workplaceID, setLastWorkplaceId]);

  useEffect(() => {
    if (!companyDataLoading && companyData?.companyConfig) {
      setHistoryFeatureVisibility(companyData.companyConfig?.roleConfig?.historyVisibilityEnabled);
    }
  }, [companyData, companyDataLoading, workplaceData, workplaceDataLoading, workplaceID, lastStatusChange]);

  const isRootPath = !['/counters', '/reasons', '/status-screen'].some((path) => params.pathname.includes(path));

  // redirects to 404 if current workplace doesnt exist in fetched data
  useEffect(() => {
    if (!companyDataLoading) {
      if (params.search.includes('loginRedirect')) {
        const cookiesDefaultWorkplace = Cookies.get('default_workplace_id');

        // default_workplace_id has updated
        if (cookiesDefaultWorkplace) {
          if (companyData.workplaces.find((workplace) => workplace.id === cookiesDefaultWorkplace)) {
            const defaultWorkplaceConfig = getWorkplaceConfig(companyData?.companyConfig, cookiesDefaultWorkplace);
            const defaultScreen = defaultWorkplaceConfig?.defaultScreen || ScreenEnum.STATUS_SCREEN;

            navigate(`/workplaces/${cookiesDefaultWorkplace}`);
            setCurrentScreen(defaultScreen);
          } else {
            navigate('/workplaces');
          }
        }
      } else {
        if (companyData?.workplaces?.find((workplace) => workplace.id === workplaceID)) {
          if (isRootPath) {
            const defaultScreen = currentWorkplaceConfig?.defaultScreen || ScreenEnum.STATUS_SCREEN;

            setCurrentScreen(defaultScreen);
          }
        } else {
          navigate('/404');
        }
      }
    }
  }, [
    companyDataLoading,
    companyData?.companyConfig,
    companyData?.workplaces,
    currentWorkplaceConfig,
    navigate,
    workplaceID,
    isRootPath,
    params.search,
  ]);

  useEffect(() => {
    setHeaderColor(
      lastStatusChange?.reason.statusColor &&
        mapStatusColorToColorString(lastStatusChange?.reason.statusColor as AndonLightColor),
    );
  }, [lastStatusChange?.reason.statusColor]);

  useEffect(() => {
    setWorkplaceNotifications(
      [...notifications.values()].filter(
        (notification) => notification.receiverId === workplaceID && notification.status === 'created',
      ),
    );
  }, [workplaceID, notifications, notifications.size]);

  const menuItems = [
    {
      label: intl.formatMessage({
        defaultMessage: 'Select workplace',
        description: 'App bar select workplace menu item text',
      }),
      url: `/workplaces`,
    },
    {
      label: intl.formatMessage(
        {
          defaultMessage: 'Change language to {languageShort}',
          description: 'App bar change language menu item text',
        },
        { languageShort: intl.locale === 'pl' ? 'Angielski' : 'Polish' },
      ),
      action: () => {
        toggleLocale();
      },
    },
  ];

  const getLeadingText = () => {
    return currentWorkplace?.name || '';
  };

  const getTitle = () => {
    return lastStatusChange?.reason?.name ? lastStatusChange.reason.name : '';
  };

  const renderLeftColumn = () => {
    if (currentScreen === ScreenEnum.COUNTERS)
      return (
        <Col col={8}>
          <Counters
            selectedCounter={selectedCounter}
            setSelectedCounter={setSelectedCounter}
            setCounterModalCallback={setCounterModalCallback}
          />
        </Col>
      );

    if (currentScreen !== ScreenEnum.STATUS_SCREEN && historyFeatureVisibility)
      return (
        <Col col={8}>
          <Reasons currentScreen={currentScreen} />
        </Col>
      );

    return (
      <Col col={12}>
        <Reasons currentScreen={currentScreen} />
      </Col>
    );
  };

  const renderRightColumn = (): React.ReactElement | null => {
    if (currentScreen === ScreenEnum.COUNTERS)
      return (
        <Col col={4} style={{ paddingLeft: 0 }}>
          <CountersKeyboard
            setOperationType={setOperationType}
            setSelectedCounter={setSelectedCounter}
            setCounterModalCallback={setCounterModalCallback}
            operationType={operationType}
            selectedCounter={selectedCounter}
          />
        </Col>
      );
    if (currentScreen !== ScreenEnum.COUNTERS && currentScreen !== ScreenEnum.STATUS_SCREEN && historyFeatureVisibility)
      return (
        <Col col={4} style={{ paddingLeft: 0 }}>
          <History workplaceID={workplaceID} />
        </Col>
      );

    return null;
  };

  const handleNotificationClose = () => {
    if (workplaceNotifications.length) {
      const notification = workplaceNotifications[0];

      setMutationQuery(
        notification.markReceived({
          optimisticUpdate: () => {
            setWorkplaceNotifications((current) => current.slice(1));
          },
          revertUpdate: () => {
            setWorkplaceNotifications((current) => [notification, ...current]);
          },
          onError: (error) => {
            notifyServerError(error, intl);
          },
        }),
      );
    }
  };

  return (
    <Container
      headerProps={{
        position: 'static',
        leadingText: getLeadingText(),
        title: getTitle(),
        menuProps: {
          items: menuItems,
        },
        styles: {
          position: 'relative',
          outline: '5px solid',
          outlineColor: headerColor,
          backgroundColor: headerColor,
          mixBlendMode: 'hard-light',
          background: `linear-gradient(329.66deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.15) 100%)`,
          backgroundSize: 'cover',
          animation: lastStatusChange?.reason.temporary ? 'pulse 3s infinite' : 'none',
          // @ts-expect-error
          '& a[href="/"]': {
            mixBlendMode: 'plus-lighter',
            filter: 'contrast(100%)',
            ...(headerColor !== AndonLightColor.red && { opacity: 0.8 }),
          },
          '& .MuiButton-root': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
            },
          },
        },
      }}
      sidebarMenuProps={{
        enabled: false,
        items: getSidebarMenuItems(intl),
      }}
    >
      <div data-testid="workplace-container">
        <StatusChangeTransitionPermissionsProvider
          fromReasonId={lastStatusChange?.reason?.id}
          provideAll={provideAllPermissions}
        >
          <Row>
            {renderLeftColumn()}
            {renderRightColumn()}
          </Row>
          <Row>
            <Col col={12}>
              <WorkplaceFooter workplaceConfig={currentWorkplaceConfig} currentScreen={currentScreen} />
            </Col>
          </Row>
        </StatusChangeTransitionPermissionsProvider>
      </div>
      <CounterModal
        open={counterModalCallback !== null}
        onCancel={() => setCounterModalCallback(null)}
        onSubmit={(note) => (counterModalCallback ? counterModalCallback(note) : null)}
      />
      {lastOrderExecution && !!lastOrderExecution.standardRateMismatched && (
        <StandardRateModal open orderExecution={lastOrderExecution} />
      )}
      {!!workplaceNotifications.length && (
        <Notification notification={workplaceNotifications[0]} onClose={handleNotificationClose} />
      )}
      <style>
        {!!headerColor &&
          `
          @keyframes pulse { 
            0% {
              background-color: ${hexToRgba(headerColor, 0.2)};
            }

            50% {
              background-color: ${hexToRgba(headerColor, 1)};
            }

            100% {
              background-color: ${hexToRgba(headerColor, 0.2)};
            }
          }
      `}
      </style>
    </Container>
  );
});

export default WorkplacePage;
