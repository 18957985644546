import { Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

interface StyledItemProps {
  statusColor?: string | null;
}

export const StyledItem = styled(Row)<StyledItemProps>`
  height: 80px;
  padding: 18px 10px;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.mineSchaft};
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 2px;
    height: calc(100% - 2px);
    background: ${({ statusColor, theme }) => statusColor || theme.colors.mineSchaft};
  }
`;
