import { Col, Row } from 'styled-bootstrap-grid';

import exclamationIcon from '@/images/exclamation.svg';

import {
  DialogStepNote as StyledDialogStepNote,
  DialogStepNoteIcon,
  DialogStepNoteText,
  DialogStepNoteTitle,
} from './styled';

interface DialogStepNoteProps {
  title: string;
  text: string;
  'data-testid'?: string;
}

const DialogStepNote: React.FC<DialogStepNoteProps> = ({ title, text, 'data-testid': dataTestID }) => {
  return (
    <StyledDialogStepNote data-testid={dataTestID}>
      <Row>
        <Col col={0.35} noGutter>
          <DialogStepNoteIcon src={exclamationIcon} />
        </Col>
        <Col col>
          <DialogStepNoteTitle>{title}</DialogStepNoteTitle>
        </Col>
      </Row>
      <Row>
        <Col offset={0.35}>
          <DialogStepNoteText>{text}</DialogStepNoteText>
        </Col>
      </Row>
    </StyledDialogStepNote>
  );
};

export default DialogStepNote;
