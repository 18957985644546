/* eslint-disable promise/catch-or-return, promise/always-return */
import { KeyboardEvent, useEffect, useRef } from 'react';

import { FormikProps } from 'formik';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash.debounce';

import { OrderExecutionModalView } from '@/components/const';
import { OrderExecutionFormValues, OrderExecutionModalState } from '@/types';

interface UseHandlersProps {
  workplaceId: string;
  orderExecutionModalState: OrderExecutionModalState;
  formProps: FormikProps<OrderExecutionFormValues>;
}
type MouseEventHandler = (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
type KeyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => Promise<void>;

const useHandlers = ({ workplaceId, orderExecutionModalState, formProps }: UseHandlersProps) => {
  const debouncedHandleNextButtonClickRef = useRef<DebouncedFunc<MouseEventHandler>>();
  const debouncedHandleKeyPressRef = useRef<DebouncedFunc<KeyPressHandler>>();
  const {
    previousView,
    setCurrentView,
    setShowAlreadyPlannedAlert,
    setShowUnsavedChangesAlert,
    validating,
    validateCurrentStep,
    moveToNextStep,
    clearSteps,
    validateCurrentField,
    moveToNextField,
    selectedOrder,
    selectedProduct,
    setOrderExecutionModalHidden,
    setOrderExecutionModalOpened,
  } = orderExecutionModalState;

  const selectedOrderRef = useRef(selectedOrder);
  const selectedProductRef = useRef(selectedProduct);

  useEffect(() => {
    const handleMoveToNext = async (e: React.MouseEvent | KeyboardEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (!validating && (await validateCurrentField())) {
        moveToNextField();
      }
      if (!validating && (await validateCurrentStep())) {
        const currentSelectedOrder = selectedOrderRef.current;
        const currentSelectedProduct = selectedProductRef.current;

        if (currentSelectedOrder) {
          if (
            !currentSelectedOrder.workplaces.length ||
            currentSelectedOrder.workplaces.some((workplace) => workplace.id === workplaceId)
          ) {
            setCurrentView(OrderExecutionModalView.PLANNED_SUMMARY);
          } else {
            setOrderExecutionModalHidden(true);

            setShowAlreadyPlannedAlert(true);
          }
        } else {
          if (currentSelectedProduct) {
            formProps.setFieldValue('product.name', currentSelectedProduct.name);
          }
          moveToNextStep();
        }
      }
    };
    debouncedHandleNextButtonClickRef.current = debounce(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        handleMoveToNext(e);
      },
      500,
      { leading: true, trailing: false },
    );
    debouncedHandleKeyPressRef.current = debounce(
      async (e: KeyboardEvent<HTMLInputElement>) => {
        handleMoveToNext(e);
      },
      500,
      { leading: true, trailing: false },
    );

    return () => {
      debouncedHandleKeyPressRef.current?.cancel();
    };
  }, [
    formProps,
    moveToNextField,
    moveToNextStep,
    setCurrentView,
    setOrderExecutionModalHidden,
    setShowAlreadyPlannedAlert,
    validating,
    validateCurrentField,
    validateCurrentStep,
    selectedOrder,
    workplaceId,
  ]);

  const handleNextButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (debouncedHandleNextButtonClickRef.current) {
      debouncedHandleNextButtonClickRef.current(e);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Tab' || e.key === 'Enter') && debouncedHandleKeyPressRef.current) {
      debouncedHandleKeyPressRef.current(e);
    }
  };

  useEffect(() => {
    selectedOrderRef.current = selectedOrder;
  }, [selectedOrder]);

  return {
    handleNextButtonClick,
    handleKeyPress,
    handleCancel: () => {
      if (formProps.dirty) {
        setShowUnsavedChangesAlert(true);

        setOrderExecutionModalHidden(true);
      } else if (previousView === OrderExecutionModalView.PLANNED) {
        setCurrentView(OrderExecutionModalView.PLANNED);
      } else {
        setOrderExecutionModalOpened(false);
      }
    },
    handleReset: (e?: React.FormEvent<HTMLFormElement>) => {
      clearSteps();

      formProps.handleReset(e);
    },
    handleSubmit: async (e: React.FormEvent<HTMLFormElement>) => {
      formProps.handleSubmit(e);
    },
  };
};

export default useHandlers;
