/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { ItemModel, ItemModelType } from "./ItemModel"
import { ItemModelSelector } from "./ItemModel.base"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { ProductModel, ProductModelType } from "./ProductModel"
import { ProductModelSelector } from "./ProductModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  item: ItemModelType;
  order: OrderModelType;
  product: ProductModelType;
}

/**
 * OrderExecutionBase
 * auto generated base class for the model OrderExecutionModel.
 */
export const OrderExecutionModelBase = withTypedRefs<Refs>()(ModelBase
  .named('OrderExecution')
  .props({
    __typename: types.optional(types.literal("OrderExecution"), "OrderExecution"),
    finishedAt: types.union(types.undefined, types.null, types.string),
    id: types.identifier,
    item: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ItemModel))),
    itemCount: types.union(types.undefined, types.null, types.number),
    note: types.union(types.undefined, types.null, types.string),
    order: types.union(types.undefined, MSTGQLRef(types.late((): any => OrderModel))),
    product: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => ProductModel))),
    standardRateMismatched: types.union(types.undefined, types.boolean),
    startedAt: types.union(types.undefined, types.string),
    workplaceId: types.union(types.undefined, types.string),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class OrderExecutionModelSelector extends QueryBuilder {
  get finishedAt() { return this.__attr(`finishedAt`) }
  get id() { return this.__attr(`id`) }
  get itemCount() { return this.__attr(`itemCount`) }
  get note() { return this.__attr(`note`) }
  get standardRateMismatched() { return this.__attr(`standardRateMismatched`) }
  get startedAt() { return this.__attr(`startedAt`) }
  get workplaceId() { return this.__attr(`workplaceId`) }
  item(builder: string | ItemModelSelector | ((selector: ItemModelSelector) => ItemModelSelector) | undefined) { return this.__child(`item`, ItemModelSelector, builder) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined) { return this.__child(`order`, OrderModelSelector, builder) }
  product(builder: string | ProductModelSelector | ((selector: ProductModelSelector) => ProductModelSelector) | undefined) { return this.__child(`product`, ProductModelSelector, builder) }
}
export function selectFromOrderExecution() {
  return new OrderExecutionModelSelector()
}

export const orderExecutionModelPrimitives = selectFromOrderExecution().finishedAt.itemCount.note.standardRateMismatched.startedAt.workplaceId
