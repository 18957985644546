import { companyConfigModelPrimitives } from '@/models/CompanyConfigModel.base';
import { deviceConfigModelPrimitives } from '@/models/DeviceConfigModel.base';
import { orderConfigModelPrimitives } from '@/models/OrderConfigModel.base';
import { orderExecutionConfigModelPrimitives } from '@/models/OrderExecutionConfigModel.base';
import { productConfigModelPrimitives } from '@/models/ProductConfigModel.base';
import { roleConfigModelPrimitives } from '@/models/RoleConfigModel.base';
import { screenSaverConfigModelPrimitives } from '@/models/ScreenSaverConfigModel.base';
import { workplaceConfigModelPrimitives } from '@/models/WorkplaceConfigModel.base';

export default {
  ALL: companyConfigModelPrimitives
    .devicesConfigs(deviceConfigModelPrimitives)
    .workplacesConfigs(
      workplaceConfigModelPrimitives
        .orderConfig(orderConfigModelPrimitives)
        .orderExecutionConfig(orderExecutionConfigModelPrimitives)
        .productConfig(productConfigModelPrimitives)
        .screenSaverConfig(screenSaverConfigModelPrimitives),
    )
    .roleConfig(roleConfigModelPrimitives)
    .toString(),
};
