import styled, { css } from 'styled-components';

interface ItemContainerProps {
  active?: boolean;
}

interface ItemDetailsProps {
  active?: boolean;
}

interface ItemDetailsRowProps {
  active?: boolean;
}

interface ItemDetailsValueProps {
  bold?: boolean;
}

export const PlannedExecutionContainer = styled.div`
  height: 608px;
  @media (max-height: 800px) {
    height: calc(100% - 96px);
  }
`;

export const ContentWrapper = styled.div`
  min-width: 740px;
  height: 100%;
  overflow-y: auto;
`;

export const ItemsHeader = styled.h3`
  margin: 0;
  padding: 0;
  line-height: 26px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.pixelWhite};
`;

export const ItemsSeparator = styled.div`
  height: 1px;
  margin: 8px 36px 32px 36px;
  background: ${({ theme }) => theme.colors.thunder};
`;

export const ItemsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ItemContainer = styled.li<ItemContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 36px;
  padding: 10px 32px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.colors.thunder};
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.mineSchaft};
  &:hover {
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    css`
      border-color: #155ed1;
      background: #2a4267;
    `}
`;

export const ItemDetails = styled.div<ItemDetailsProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 450px;
  margin-right: 32px;
  padding-right: 16px;
  border-right: 1px solid ${({ theme }) => theme.colors.thunder};
  ${({ active }) =>
    active &&
    css`
      border-right-color: #3f5880;
    `}
`;

export const ItemDetailsRow = styled.div<ItemDetailsRowProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
    padding-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
    border-bottom: 1px solid ${({ active, theme }) => (active ? '#3f5880' : theme.colors.thunder)};
  }
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const ItemDetailsLabel = styled.p`
  margin: 0;
  font-weight: 400;
`;

export const ItemDetailsValue = styled.p<ItemDetailsValueProps>`
  margin: 0;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;

export const ItemRadioButton = styled.input`
  margin: 0;
  width: 28px;
  height: 28px;
  &:hover {
    cursor: pointer;
  }
`;
