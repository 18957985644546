import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { useLoginStatus } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { v4 as uuidv4 } from 'uuid';

import { ConfirmationModal, DialogMessage } from '@/components/core';
import { useStartOrderExecution, useStore } from '@/hooks';
import { OrderExecutionModel, StartOrderExecutionPayloadModelType, useQuery } from '@/models';
import { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

interface AlreadyPlannedAlertModalProps {
  state: OrderExecutionModalState;
}

const AlreadyPlannedAlertModal: React.FC<AlreadyPlannedAlertModalProps> = observer(({ state }) => {
  const { authResponse } = useLoginStatus();
  const rootStore = useStore();
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { setQuery: setUpdateOrderQuery } = useQuery();
  const startOrderExecution = useStartOrderExecution(state);
  // use refs to provide current values for callbacks
  const startOrderExecutionRef = useRef(startOrderExecution);
  const [saving, setSaving] = useState<boolean>(false);
  const intl = useIntl();

  useEffect(() => {
    startOrderExecutionRef.current = startOrderExecution;
  }, [startOrderExecution]);

  const {
    showAlreadyPlannedAlert,
    setShowAlreadyPlannedAlert,
    clearSteps,
    selectedOrder,
    formProps,
    setOrderExecutionModalHidden,
    setOrderExecutionModalOpened,
  } = state;

  const updateOrderWorkplaces = () => {
    if (selectedOrder) {
      setUpdateOrderQuery((store) =>
        store.mutateUpdateOrder({
          input: {
            id: selectedOrder.id,
            workplaceIds: [...selectedOrder?.workplaces.map((workplace) => workplace.id), workplaceID],
          },
        }),
      );
    }
  };

  const afterSubmitCallback = () => {
    clearSteps();

    formProps?.resetForm();

    setShowAlreadyPlannedAlert(false);

    setOrderExecutionModalHidden(false);
    setOrderExecutionModalOpened(false);

    setSaving(false);
  };

  const handleConfirm = async () => {
    if (authResponse && startOrderExecutionRef.current) {
      if (selectedOrder) {
        setSaving(true);

        updateOrderWorkplaces();

        const selectedItem = selectedOrder.items[0];

        const orderExecution = OrderExecutionModel.create({
          id: uuidv4(),
          workplaceId: workplaceID,
          ...(selectedItem && {
            itemCount: selectedItem.count,
          }),
        });
        rootStore.addOrderExecution(orderExecution);

        orderExecution.update({
          item: selectedItem,
          order: selectedOrder,
          product: selectedItem?.product,
        });
        const query = startOrderExecutionRef.current(orderExecution);

        const { startOrderExecution } = (await query.currentPromise()) as {
          startOrderExecution: StartOrderExecutionPayloadModelType;
        };

        if (startOrderExecution.errors) {
          const nonFieldError = startOrderExecution.errors.find((error) => error.field === 'base');

          if (nonFieldError) {
            return null;
          }
          // TODO error handling
        } else {
          afterSubmitCallback();
        }
      }
    }
  };
  const handleCancel = () => {
    setShowAlreadyPlannedAlert(false);

    setOrderExecutionModalHidden(false);
  };

  return (
    <ConfirmationModal
      isOpen={showAlreadyPlannedAlert}
      title={intl.formatMessage({
        defaultMessage: 'Order in progress',
        description: 'Already planned alert modal title',
      })}
      data-testid="already-planned-alert-modal"
      rejectButtonText={intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'Already planned alert reject button text',
      })}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Start',
        description: 'Already planned alert confirm button text',
      })}
      pending={saving}
      onSuccess={handleConfirm}
      onFailure={handleCancel}
    >
      <DialogMessage>
        <FormattedMessage
          defaultMessage="The selected order is already being processed at the workplaces: {workplaces}. Do you want to join its execution?"
          description="Already planned alert modal message"
          values={{ workplaces: selectedOrder?.workplaces?.map((workplace) => workplace.name).join(', ') }}
        />
      </DialogMessage>
    </ConfirmationModal>
  );
});

export default AlreadyPlannedAlertModal;
