import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import OrderExecutionForm from '@/components/forms/OrderExecutionForm';
import { getWorkplaceConfig } from '@/helpers';
import { useCompanyData } from '@/providers';
import { ReasonsParamTypes } from '@/types';

import { OrderExecutionModalProps } from '../..';

const ManualExecutionView: React.FC<OrderExecutionModalProps> = observer(({ state }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const { data: companyData, loading: loadingCompanyData } = useCompanyData();
  const workplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);
  const orderConfig = workplaceConfig?.orderConfig;
  const orderExecutionConfig = workplaceConfig?.orderExecutionConfig;
  const productConfig = workplaceConfig?.productConfig;
  const { setSelectedOrder } = state;

  useEffect(() => {
    setSelectedOrder(null);
  }, [setSelectedOrder]);

  return (
    (!loadingCompanyData && orderConfig && productConfig && (
      <OrderExecutionForm
        orderExecutionModalState={state}
        orderConfig={orderConfig}
        orderExecutionConfig={orderExecutionConfig}
        productConfig={productConfig}
      />
    )) ||
    null
  );
});

export default ManualExecutionView;
