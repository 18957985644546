import styled from 'styled-components';

export const FieldError = styled.span`
  position: absolute;
  display: flex;
  margin-top: 4px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.redOrangeJuice};
`;

export const FieldErrorIcon = styled.img`
  margin-right: 4px;
`;
