import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { notify, useLoginStatus } from 'andoncloud-sdk';
import { observer } from 'mobx-react-lite';
import { castToReferenceSnapshot } from 'mobx-state-tree';
import { Moment } from 'moment';

import { ConfirmationModal, DialogFooterLink, DialogMessage } from '@/components/core';
import { getWorkplaceConfig } from '@/helpers';
import { notifyServerError } from '@/helpers/errors';
import { useCurrentOrderExecution, useLastStatusChange, useStore } from '@/hooks';
import { ReasonModelType, useQuery } from '@/models';
import HistoryItemModel, { HistoryItemModelType, HistoryItemType } from '@/models/HistoryItemModel';
import { useCompanyData, useWorkplaceData } from '@/providers';
import { OrderExecutionModalState, ReasonsParamTypes } from '@/types';

interface ExecutionInProgressAlertModalProps {
  state: OrderExecutionModalState;
}

const ExecutionInProgressAlertModal: React.FC<ExecutionInProgressAlertModalProps> = observer(({ state }) => {
  const { workplaceID } = useParams<keyof ReasonsParamTypes>() as ReasonsParamTypes;
  const rootStore = useStore();
  const { authResponse } = useLoginStatus();
  const { data: companyData, loading: companyDataLoading } = useCompanyData();
  const { data: workplaceData, loading: workplaceDataLoading } = useWorkplaceData();
  const lastStatusChange = useLastStatusChange();
  const currentOrderExecution = useCurrentOrderExecution();
  const [afterPauseReason, setAfterPauseReason] = useState<ReasonModelType | null>();
  const [afterFinishReason, setAfterFinishReason] = useState<ReasonModelType | null>();
  const { setQuery } = useQuery();
  const intl = useIntl();

  const workplaceConfig = getWorkplaceConfig(companyData?.companyConfig, workplaceID);
  const {
    showExecutionInProgressAlert,
    setShowExecutionInProgressAlert,
    startedOrders,
    addStartedOrder,
    removeStartedOrder,
    addPlannedOrder,
    removePlannedOrder,
    setOrderExecutionModalOpened,
  } = state;

  useEffect(() => {
    if (!companyDataLoading && !workplaceDataLoading) {
      setAfterPauseReason(
        workplaceData.reasons.find((reason) => reason.id === workplaceConfig?.orderExecutionConfig.afterPauseReasonId),
      );
      setAfterFinishReason(
        workplaceData.reasons.find((reason) => reason.id === workplaceConfig?.orderExecutionConfig.afterStopReasonId),
      );
    }
  }, [
    companyDataLoading,
    workplaceData,
    workplaceDataLoading,
    workplaceConfig?.orderExecutionConfig.afterPauseReasonId,
    workplaceConfig?.orderExecutionConfig.afterStopReasonId,
  ]);

  const handleSuspend = () => {
    if (authResponse && currentOrderExecution && lastStatusChange) {
      let createdStatusChangeHistoryItem: HistoryItemModelType;
      let createdOrderInfoHistoryItem: HistoryItemModelType;

      setQuery(() =>
        currentOrderExecution.pause({
          workplaceId: workplaceID,
          userData: authResponse.user,
          lastStatusChange,
          reason: afterPauseReason || lastStatusChange.reason,
          optimisticUpdate: (statusChange) => {
            createdStatusChangeHistoryItem = HistoryItemModel.create({
              date: statusChange.startedAt as Moment,
              instance: castToReferenceSnapshot(statusChange),
              type: HistoryItemType.STATUS_CHANGE,
            });

            createdOrderInfoHistoryItem = HistoryItemModel.create({
              date: statusChange.startedAt as Moment,
              instance: castToReferenceSnapshot(currentOrderExecution),
              type: HistoryItemType.ORDER_EXECUTION_END,
            });

            rootStore.addHistoryItem(createdOrderInfoHistoryItem);
            rootStore.addHistoryItem(createdStatusChangeHistoryItem);

            setShowExecutionInProgressAlert(false);

            setOrderExecutionModalOpened(true);
          },
          revertUpdate: () => {
            rootStore.removeHistoryItem(createdOrderInfoHistoryItem);
            rootStore.removeHistoryItem(createdStatusChangeHistoryItem);
          },
          onSuccess: () => {
            notify.success(
              intl.formatMessage({
                defaultMessage: 'Order execution has been suspended',
                description: 'Order execution suspended notify success message',
              }),
            );
          },
          onError: (error) => {
            notifyServerError(error, intl);
          },
        }),
      );
    }
  };

  const handleFinish = () => {
    if (authResponse && currentOrderExecution && lastStatusChange) {
      let createdStatusChangeHistoryItem: HistoryItemModelType;
      let createdOrderInfoHistoryItem: HistoryItemModelType;
      let startedOrderRemoved = false;
      let plannedOrderRemoved = false;

      setQuery(() =>
        currentOrderExecution.stop({
          workplaceId: workplaceID,
          userData: authResponse.user,
          lastStatusChange,
          reason: afterFinishReason || lastStatusChange.reason,
          optimisticUpdate: (statusChange) => {
            createdStatusChangeHistoryItem = HistoryItemModel.create({
              date: statusChange.startedAt as Moment,
              instance: castToReferenceSnapshot(statusChange),
              type: HistoryItemType.STATUS_CHANGE,
            });

            createdOrderInfoHistoryItem = HistoryItemModel.create({
              date: statusChange.startedAt as Moment,
              instance: castToReferenceSnapshot(currentOrderExecution),
              type: HistoryItemType.ORDER_EXECUTION_END,
            });

            rootStore.addHistoryItem(createdOrderInfoHistoryItem);
            rootStore.addHistoryItem(createdStatusChangeHistoryItem);

            if (startedOrders?.find((order) => order.id === currentOrderExecution.order.id)) {
              removeStartedOrder(currentOrderExecution.order);

              startedOrderRemoved = true;
            } else {
              removePlannedOrder(currentOrderExecution.order);

              plannedOrderRemoved = true;
            }
          },
          revertUpdate: () => {
            if (startedOrderRemoved) {
              addStartedOrder(currentOrderExecution.order);
            } else if (plannedOrderRemoved) {
              addPlannedOrder(currentOrderExecution.order);
            }
            rootStore.removeHistoryItem(createdOrderInfoHistoryItem);
            rootStore.removeHistoryItem(createdStatusChangeHistoryItem);
          },
          onSuccess: () => {
            setShowExecutionInProgressAlert(false);

            setOrderExecutionModalOpened(true);

            notify.success(
              intl.formatMessage({
                defaultMessage: 'Order execution has been finished',
                description: 'Order execution finished notify success message',
              }),
            );
          },
          onError: (error) => {
            notifyServerError(error, intl);
          },
        }),
      );
    }
  };
  const handleCancel = () => {
    setShowExecutionInProgressAlert(false);
  };

  return (
    <ConfirmationModal
      isOpen={showExecutionInProgressAlert}
      data-testid="execution-in-progress-alert-modal"
      actionLink={
        <DialogFooterLink onClick={handleSuspend}>
          <FormattedMessage
            defaultMessage="Suspend"
            description="Execution in progress alert modal suspend link text"
          />
        </DialogFooterLink>
      }
      rejectButtonText={intl.formatMessage({
        defaultMessage: 'Cancel',
        description: 'Execution in progress alert modal rejection button text',
      })}
      confirmButtonText={intl.formatMessage({
        defaultMessage: 'Finish',
        description: 'Execution in progress alert modal confirm button text',
      })}
      onSuccess={handleFinish}
      onFailure={handleCancel}
    >
      <DialogMessage
        title={intl.formatMessage({
          defaultMessage: 'Attention!',
          description: 'Execution in progress alert modal title',
        })}
      >
        <FormattedMessage
          defaultMessage="Order {orderNumber} has not been completed. To start another order, you must complete the current order or pause it to continue at a later time."
          description="Execution in progress alert modal message"
          values={{ orderNumber: currentOrderExecution?.order?.number }}
        />
      </DialogMessage>
    </ConfirmationModal>
  );
});

export default ExecutionInProgressAlertModal;
