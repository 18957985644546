import { Col, Row } from 'styled-bootstrap-grid';

import {
  DialogStepSummary as StyledDialogStepSummary,
  DialogStepSummaryRecord,
  DialogStepSummaryRecordLabel,
  DialogStepSummaryRecordUnit,
  DialogStepSummaryRecordValue,
} from './styled';

export interface DialogStepSummaryRecordType {
  label?: string;
  value: string | number;
  unit?: string;
}

interface DialogStepSummaryProps {
  records: DialogStepSummaryRecordType[];
  'data-testid'?: string;
}

export const DialogStepSummary: React.FC<DialogStepSummaryProps> = ({ records, 'data-testid': dataTestID }) => {
  return (
    <StyledDialogStepSummary data-testid={dataTestID}>
      <Row noGutter>
        {records.map((record, index) => (
          <Col key={index} col noGutter>
            <DialogStepSummaryRecord>
              {record.label && <DialogStepSummaryRecordLabel>{record.label}</DialogStepSummaryRecordLabel>}
              <DialogStepSummaryRecordValue>
                {record.value}
                {record.unit && <DialogStepSummaryRecordUnit>{record.unit}</DialogStepSummaryRecordUnit>}
              </DialogStepSummaryRecordValue>
            </DialogStepSummaryRecord>
          </Col>
        ))}
      </Row>
    </StyledDialogStepSummary>
  );
};
