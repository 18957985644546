import { useContext, useEffect, useState } from 'react';

import { observer } from 'mobx-react-lite';

import { TextTruncate } from '@/components/core';
import { mapStatusColorToColorString } from '@/helpers/mapStatusColorToColorString';
import { getDurationText } from '@/helpers/status-changes';
import { modalContext } from '@/hooks/useHistoryItemModal';
import useStatusChangeModalDetails from '@/hooks/useStatusChangeModalDetails';
import aiBlueIcon from '@/images/aiBlue.svg';
import { AndonLightColor, ReasonModelType, StatusChangeModelType } from '@/models';
import { useWorkplaceData } from '@/providers';
import { ModalStore } from '@/types';

import HistoryButton, { HistoryButtonIcon, HistoryButtonText } from '../HistoryButton';

import { StatusChangeColor, StatusChangeName } from './styled';

export interface StatusChangeProps {
  status: StatusChangeModelType;
}

const StatusChange: React.FC<StatusChangeProps> = observer(({ status }) => {
  const [reason, setReason] = useState<ReasonModelType>(status.reason);
  const [durationText, setDurationText] = useState<string>();
  const modalStore: ModalStore | null = useContext(modalContext);
  const modalDetails = useStatusChangeModalDetails(status);
  const { data } = useWorkplaceData();
  const { reasons } = data;
  const handleClick = () => {
    if (modalStore) {
      modalStore.setDetails(modalDetails);
      modalStore.toggleOpen();
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    setReason(status.reason);
    setDurationText(getDurationText(status));

    if (!status.finishedAt) {
      interval = setInterval(() => {
        setDurationText(getDurationText(status));
      }, 1000);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [status, status.reason]);

  return (
    <HistoryButton data-testid={`status-change-button-${status.id}`} onClick={handleClick}>
      <StatusChangeColor
        backgroundColor={mapStatusColorToColorString(reason?.statusColor as AndonLightColor)}
        animate={reason?.temporary}
      />
      <HistoryButtonText>
        <TextTruncate>{`${reason?.name} (${durationText})`}</TextTruncate>
      </HistoryButtonText>
      {status.reasonHasBeenRecommended && <HistoryButtonIcon src={aiBlueIcon} />}
      <StatusChangeName data-testid="status-change-name">
        <TextTruncate>{reason?.parentId ? reasons.find(({ id }) => id === reason.parentId)?.name : ''}</TextTruncate>
      </StatusChangeName>
    </HistoryButton>
  );
});

export default StatusChange;
