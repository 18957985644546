import styled, { css } from 'styled-components';

interface ButtonProps {
  selected: boolean;
  textColor?: string;
  backgroundColor?: string;
  isLastColumn?: boolean;
}

export const Button = styled.button<ButtonProps>`
  margin: 0.5px 0;
  background-color: ${({ theme }) => theme.colors.youtubeBlack};
  position: relative;
  padding: 45px 18px;
  border: none;
  display: grid;
  text-align: left;
  grid-gap: 20px;
  grid-template-columns: auto 1fr auto;
  font-size: 18px;
  line-height: 29px;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s ease-in;
  &:nth-child(2n) {
    background-color: ${({ theme }) => theme.colors.mineSchaft};
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.dodgerBlue};
  }
  ${({ selected }) =>
    selected &&
    css`
      &:hover {
        & > .arrow-icon {
          transform: rotate(180deg);
        }
      }
    `}
  ${(props: ButtonProps) =>
    props.isLastColumn &&
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor} !important;
      color: ${({ theme }) => props.textColor};
    `}
  ${(props: ButtonProps) =>
    props.selected &&
    css`
      background-color: ${props.backgroundColor} !important;
      color: ${({ theme }) => props.textColor};
    `}
  &:disabled {
    pointer-events: none;
    filter: brightness(0.4);
  }
  @media (max-width: 1600px) {
    font-size: 16px;
  }
`;

export const ButtonIcon = styled.img`
  color: blue;
  height: 30px;
`;
