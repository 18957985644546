/* This is a mst-gql generated file, don't modify it manually */
/* eslint-disable */
/* tslint:disable */

import { types } from "mobx-state-tree"
import { MSTGQLRef, QueryBuilder, withTypedRefs } from "mst-gql"
import { ModelBase } from "./ModelBase"
import { MutationErrorModel, MutationErrorModelType } from "./MutationErrorModel"
import { MutationErrorModelSelector } from "./MutationErrorModel.base"
import { OrderModel, OrderModelType } from "./OrderModel"
import { OrderModelSelector } from "./OrderModel.base"
import { RootStoreType } from "./index"


/* The TypeScript type that explicits the refs to other models in order to prevent a circular refs issue */
type Refs = {
  order: OrderModelType;
}

/**
 * UpdateOrderPayloadBase
 * auto generated base class for the model UpdateOrderPayloadModel.
 *
 * Autogenerated return type of UpdateOrder
 */
export const UpdateOrderPayloadModelBase = withTypedRefs<Refs>()(ModelBase
  .named('UpdateOrderPayload')
  .props({
    __typename: types.optional(types.literal("UpdateOrderPayload"), "UpdateOrderPayload"),
    errors: types.union(types.undefined, types.null, types.array(types.late((): any => MutationErrorModel))),
    order: types.union(types.undefined, types.null, MSTGQLRef(types.late((): any => OrderModel))),
  })
  .views(self => ({
    get store() {
      return self.__getStore<RootStoreType>()
    }
  })))

export class UpdateOrderPayloadModelSelector extends QueryBuilder {
  errors(builder: string | MutationErrorModelSelector | ((selector: MutationErrorModelSelector) => MutationErrorModelSelector) | undefined) { return this.__child(`errors`, MutationErrorModelSelector, builder) }
  order(builder: string | OrderModelSelector | ((selector: OrderModelSelector) => OrderModelSelector) | undefined) { return this.__child(`order`, OrderModelSelector, builder) }
}
export function selectFromUpdateOrderPayload() {
  return new UpdateOrderPayloadModelSelector()
}

export const updateOrderPayloadModelPrimitives = selectFromUpdateOrderPayload()
